import './gee' // 引入极验库
import React, { useEffect, useRef, useState } from 'react'
import './gee.scss'

interface GeeSdkProps {
  config: {
    captchaId: string
    product: string
    protocol: string
  }
}

export const useGeeSdk = (props: GeeSdkProps) => {
  const [state, setState] = useState('loading')
  const captchaRef = useRef<any>(null)
  const reset = () => {
    setState('loading')
  }
  const showCaptcha = () => {
    captchaRef.current?.showCaptcha()
  }

  const getValidate = () => {
    return captchaRef.current?.getValidate()
  }

  useEffect(() => {
    if (captchaRef.current) {
      return
    }
    const handler = (obj: any) => {
      captchaRef.current = obj
      obj
        .appendTo('#geeSdk')
        .onReady(() => {
          setState('ready')
        })
        .onError(() => {
          setState('error')
        })
        .onClose(() => {
          setState('close')
        })
        .onSuccess(() => {
          setState('success')
          const result = obj.getValidate()
        })
    }
    if (props) {
      window.initGeetest4(props.config, handler)
    }
  }, [props])

  return {
    state,
    reset,
    getValidate,
    showCaptcha
  }
}
