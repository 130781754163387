// ThemeContext.tsx
import localCache from '@/utils/cache'
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect
} from 'react'

interface ThemeContextProps {
  theme: 'day' | 'night'
  toggleTheme: () => void
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined)

interface ThemeProviderProps {
  children: ReactNode
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<'day' | 'night'>(localCache.get('theme','day')) // Initial theme can be 'day' or 'night'

  const toggleTheme = () => {
    const thisTheme = theme === 'day' ? 'night' : 'day'
    setTheme(thisTheme)
    localCache.set('theme',thisTheme)
    const themeColorMeta = document.querySelector('meta[name="theme-color"]');
    if(themeColorMeta) {
      themeColorMeta.setAttribute('content',thisTheme === 'day' ? '#fffbf2' : '#17181a');
    }
  }

  useEffect(() => {
    document.documentElement.dataset.theme = theme
  }, [theme])

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = (): ThemeContextProps => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}
