import { BaseAxios } from '@/net/axios'
import qs from 'qs'
import {
  config,
  getApiSign,
  onDynamicHeader,
  onHandleResponseData
} from './axiosConfig'
import {
  BareFetcher,
  Fetcher,
  KeyedMutator,
  SWRConfiguration,
  SWRHook,
  SWRResponse
} from 'swr/dist/_internal'
import useSWR from 'swr'
import useStorage from '@/commonHooks/useStorage'
import { aesEncrypt } from './apiCrypto'
import { generateRandomString } from '@/utils'
import localCache from '@/utils/cache'
import { isEmpty } from 'lodash'
import { useAuth } from '@/provides/authProvider'
import { CacheApi } from './cacheApi'

const axios = new BaseAxios({
  requestConfig: config,
  onDynamicHeader: onDynamicHeader,
  onHandleResponseData: onHandleResponseData,
  handleSign: getApiSign
})

export const apiFetcher = <T>(API: any, {arg}: any = {}): Promise<T> => {
  const { formData = false, path = '', needToken = true , customHeaders = {} } = API
  let params = arg
  if (formData) {
    params = new URLSearchParams(qs.stringify(arg))
  }
  const headers = { ...customHeaders }

  if (needToken) {
    headers[ 'X-API-Token'] = window._syswm_token || `${localCache.get('authToken')}`
    // headers = {
    //   'X-API-Token': `${localCache.get('authToken')}`
    // }
  }
  let apiPath = path
  if(window.safeSwitch) {
     const originalPath = apiPath;
     const timestamp = Math.floor(Date.now() / 1000).toString(); // 生成 11 位时间戳
     const dataToEncrypt = timestamp + originalPath;
     const encrypt = aesEncrypt(dataToEncrypt)
     apiPath = '/xxxx/' + encrypt
  }
  return axios
    .request<T | any>({
      ...API,
      path:apiPath
    }, params, {
      headers
    })
    .then((res) => {
      return res
    })
}

export class SWRCenter {
  private static instance: SWRCenter | null = null
  private cacheKey: string = ''
  public static getInstance(): SWRCenter {
    if (!SWRCenter.instance) {
      SWRCenter.instance = new SWRCenter()
    }
    return SWRCenter.instance
  }

  constructor() {
    this.randomKey()
  }

  /**只有退出登录后 再刷新key */
  public randomKey() {
    this.cacheKey = generateRandomString(10)
  }

  public getCacheKey(): string | undefined {
    return SWRCenter.instance?.cacheKey
  }
}

export interface IFSWRResponse {
  isLoading: boolean
  data: any
  error: any
  mutate: KeyedMutator<any>
  isValidating: boolean
  randomKey: () => void,
  cacheKey: string
}

export const useSWRExpand = (
  key: string,
  fetcher: any,
  options?: SWRConfiguration<any, any, BareFetcher<any>>,
  author?: boolean,
  isCache?: boolean
): IFSWRResponse => {
  const { randomKey, cacheKey } = useSWRCacheKey(key);
  const apiKey = SWRCenter.getInstance().getCacheKey() + key + cacheKey;
  const { isLogin } = useAuth();

  // 检查是否需要使用本地缓存
  let initialData;
  if (isCache) {
    // 尝试从本地缓存获取数据
    initialData = CacheApi.getCacheData({ cacheKey: key });
  }

  if(initialData) {
    //console.log('缓存数据',initialData)
  }
  const result = useSWR<any>(
    apiKey,
    author === undefined ? fetcher : (author ? (isLogin ? fetcher : null) : fetcher),
    {
      ...options,
      fallbackData: initialData,  // 使用本地缓存数据作为初始数据
      onSuccess:(data)=>{
        if (isCache) {
          // 当数据成功获取后，更新本地缓存
          CacheApi.cacheData({
            expired: Infinity,  // 设置缓存过期时间，可根据需要调整
            cacheKey: key,
            data,
          });
        }
      }
    }
  );

  return {
    ...result,
    randomKey,
    cacheKey,
  };
};

const g_swrLoaclData = {}
export const useSWRLocal = (key: string,defaultValue?: any) => {
  const localKey = SWRCenter.getInstance().getCacheKey() + key
  const result = useSWR<any>(
    localKey,
    async () => {
      return isEmpty(g_swrLoaclData[localKey]) ? defaultValue : g_swrLoaclData[localKey]
    },
    {
      dedupingInterval: 30 * 24 * 60 * 1000 * 60
    }
  )
  const mutate = (data) => {
    g_swrLoaclData[localKey] = {
      ...data
    }
    result.mutate()
  }
  return {
    ...result,
    mutate
  }
}

const useSWRCacheKey = (props) => {
  const [key, setKey] = useStorage(props, '')

  const randomKey = () => {
    const keys = generateRandomString(5)
    setKey(keys)
  }

  return {
    cacheKey: key || '',
    randomKey
  }
}
