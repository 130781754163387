import React from 'react'
import AutomaticGeneratedRoutes from './index'
import LazyCompoment from '@/compoments/lazyCompoment/lazyCompoment'
import ProtectedLoginProvider from '@/provides/protectedLoginProvider'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams
} from 'react-router-dom'


const RouteCenter = () => {
  const { state } = useLocation()
  return (
    <Routes>
      {AutomaticGeneratedRoutes.map((v: any, index: number) => {
        return (
          <Route
            key={index}
            path={v.customPath || v.path}
            element={
              v.author ? (
                <ProtectedLoginProvider>
                  <LazyCompoment component={v.component}></LazyCompoment>
                </ProtectedLoginProvider>
              ) : (
                <LazyCompoment component={v.component}></LazyCompoment>
              )
            }
          >
            {v?.children?.map((v: any, index: number) => {
              return (
                <Route
                  key={index}
                  path={v.customPath || v.path}
                  element={
                    v.author ? (
                      <ProtectedLoginProvider>
                        <LazyCompoment component={v.component}></LazyCompoment>
                      </ProtectedLoginProvider>
                    ) : (
                      <LazyCompoment component={v.component}></LazyCompoment>
                    )
                  }
                ></Route>
              )
            })}
            {
              // 设置默认子路由
              v?.defaultRoute && (
                <Route
                  index
                  element={
                    <Navigate
                      replace={true}
                      to={v?.defaultRoute}
                      state={state}
                    ></Navigate>
                  }
                />
              )
            }
          </Route>
        )
      })}
      <Route path={'*'} element={<Navigate to="/"></Navigate>}></Route>
    </Routes>
  )
}

export default React.memo(RouteCenter)
