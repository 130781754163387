/**
 * LoadingUI 组件
 * 该组件是一个通用的状态管理组件，用于处理加载状态、错误状态和无数据状态。通过接收不同的属性，
 * 该组件可以展示加载动画、错误信息以及无数据的提示。
 *
 * 组件属性：
 * - loading (boolean): 是否处于加载状态，默认值为 true。加载时显示加载动画。
 * - children (ReactNode): 子元素，在加载完成且没有错误时显示的内容。
 * - fullSceen (boolean): 是否全屏显示加载动画或错误信息，默认值为 true。
 * - className (string): 自定义样式类名，可以用于覆盖默认样式。
 * - height (string | number): 指定容器的高度。
 * - data (object): 用于判断是否有数据展示的对象。如果对象有内容，则显示子元素。
 * - error (Error | string): 错误信息对象或字符串。当存在错误时，显示错误信息和重试按钮。
 * - noDataText (string): 无数据时显示的文本，默认值为 '暂无数据'。
 * - retryFunc (function): 错误状态下点击重试按钮的回调函数。
 *
 * 使用场景：
 * 适用于需要处理异步数据加载的场景，如数据请求、分页加载等。提供简洁的状态管理和显示。
 *132
 * 创建人: potato
 * 如需扩展功能，请联系创建人 potato
 */

 import React, { PropsWithChildren } from 'react'
 import styles from './index.module.scss'
 import NoData from '../noData'
 import classNames from 'classnames'
 import Spin from '../spin'
 import HeaderUI from '../headerUI'
 import { LoadingUIProps } from './interface'
 
 const LoadingUI = (props: PropsWithChildren<LoadingUIProps>) => {
   const {
     loading = true,
     children,
     fullSceen = true,
     className = '',
     height,
     data,
     error,
     noDataText = '暂无数据',
     retryFunc,
     noDataButtonText = '',
     noDataButtonFunc,
     showErrorHeader = false,
     noDataButtonSize,
     count = 3
   } = props
 
   // 处理样式类
   const containerClass = classNames(styles.loading_ui, className, {
     [styles.full_sceen]: fullSceen
   })
 
   // 处理错误信息样式
   const errorClass = classNames(styles.error, className, {
     [styles.full_sceen]: fullSceen
   })
 
   if (loading) {
     return (
       <div className={containerClass} style={{ height }}>
         <Spin count={count} />
       </div>
     )
   }
 
   if (error) {
     return (
       <div className={errorClass} style={{ height }}>
         {showErrorHeader && (
           <HeaderUI
             title={'页面错误'}
             showBack
             onClickBack={() => navigateTo(-1)}
           />
         )}
         <span>{error?.message || error}</span>
         <button
           onClick={(e) => {
             e.preventDefault()
             e.stopPropagation()
             retryFunc?.()
           }}
         >
           点击重试
         </button>
       </div>
     )
   }
 
   if (data && Object.keys(data).length > 0) {
     return <>{children}</>
   }
 
   return (
     <NoData
       descText={noDataText}
       btnText={noDataButtonText}
       cliclAction={noDataButtonFunc}
       noDataButtonSize={noDataButtonSize}
     />
   )
 }
 
 export default LoadingUI
 