import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import languages from '@/assets/languages/languages.json'

interface TranslationResources {
  [key: string]: {
    [namespace: string]: any
  }
}

let resources: any = {}
// if (process.env.NODE_ENV === 'development') {
//   // 动态加载翻译文件
//   const loadTranslations = (context: any): TranslationResources => {
//     const translations: TranslationResources = {}
//     context.keys().forEach((key) => {
//       const path = key.split('/')
//       const lang = path[1]
//       const namespace = path[2].replace('.json', '')

//       if (!translations[lang]) {
//         translations[lang] = {}
//       }
//       translations[lang][namespace] = context(key)
//     })
//     return translations
//   }

//   // 使用 require.context 动态加载翻译文件
//   const translationContext = require.context('./', true, /\.json$/)
//   resources = loadTranslations(translationContext)
// } else {
  resources = languages
//}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: process.env.REACT_APP_DEFAULT_LANGUAGE, // 设置回退语言
  lng: process.env.REACT_APP_DEFAULT_LANGUAGE, // 确保默认使用回退语言
  ns: Object.keys(
    resources[process.env.REACT_APP_DEFAULT_LANGUAGE || ''] || resources.zh
  ),
  defaultNS: 'common',
  interpolation: {
    escapeValue: false // React 已经处理转义
  }
})

export default i18n
