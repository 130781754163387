import React, { ReactNode, Suspense } from 'react'
import Spin from '../spin'
import styles from './index.module.scss'
// 定义 props 类型，
interface LazyComponentProps {
  component: any
  props?: any
}

// 定义懒加载组件
export default function LazyCompoment({
  component: Component,
  props
}: LazyComponentProps) {
  return (
    <Suspense
      fallback={
        <div className={styles.loading_content}>
           <Spin className={styles.loading}/>
        </div>
      }>
      <Component {...props} />
    </Suspense>
  )
}
