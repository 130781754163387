import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '@/provides/authProvider'
import { useUserInfo } from '@/commonApi'
import LoadingUI from '@/compoments/loadingUI'

interface ProtectedRouteProps {
  children: any
}

const ProtectedLoginProvider = ({ children }: ProtectedRouteProps) => {
  const { token } = useAuth() // 使用 useAuth 来获取当前的认证 token
  const location = useLocation() // 获取当前的路由位置
  const { isLoading, data, error, mutate } = useUserInfo()
  
  if (!token) {
    // 如果没有 token，则重定向到登录页面，并在登录后返回当前页面
    return <Navigate to="/login" state={{ from: location }} replace />
  }
  // 如果有 token，渲染 children 组件
  return (
    <LoadingUI loading={isLoading} data={data} error={error} retryFunc={mutate} count={4}>
      {children}
    </LoadingUI>
  )
}

export default ProtectedLoginProvider
