/*
 * @Author: Mark
 * @Date: 2024-10-25 16:49:50
 * @LastEditTime: 2024-10-28 15:11:42
 * @LastEditors: MarkMark
 * @Description: 佛祖保佑无bug
 * @FilePath: /h5-skin-two/src/commonHooks/useNavigateToPlus.ts
 */
import { Navigate } from 'react-router-dom';
/*
 * @Author: Mark
 * @Date: 2024-10-22 15:10:43
 * @LastEditTime: 2024-10-22 20:15:35
 * @LastEditors: MarkMark
 * @Description: 佛祖保佑无bug
 * @FilePath: /h5-skin-one/src/commonHooks/useNavigateToPlus.ts
 */
import { useAuth } from '@/provides/authProvider';
import { platformInvoke } from '@/utils';
import { useCallback } from 'react';
import { RouterPath } from '@/router/routerPath';
import { CardName } from '@/views/withdraw/central/compoments/bankCard/constant';

/**
 * h5与原生js交互功能如下:
 */

export const EnmuSysOSPath ={
     //首页
     home: {key:'home', path: '/main/home', h5Path:'/home' },
    //优惠列表
    promotion: {key:'promotion', path: '/main/promotion', h5Path: 'page' },
    //赞助
    sponsor: {key:'sponsor', path: '/main/sponsor', h5Path: 'page' },
    //个人中心
    mine: {key:'mine', path: '/main/mine', h5Path: 'page' },
    //客服
    service: {key:'service', path: '/main/service', h5Path: RouterPath.CustomerService },
    //存款
    deposit: {key:'deposit', path: '/wallet/deposit', h5Path: RouterPath.Deposit },
    //转账
    transfer: {key:'transfer', path: '/wallet/transfer', h5Path: RouterPath.Transfer},
    //取款
    withdrawl: {key:'withdrawl', path: '/wallet/withdrawl', h5Path: RouterPath.Withdraw },
    //添加银行卡
    addbankcard: {key:'addbankcard', path: '/wallet/addbankcard', h5Path:`addPay/${CardName.General}` },
    //绑定手机号
    personinfo: {key:'personinfo', path: '/user/personinfo', h5Path: RouterPath.IdVerification },
    //分享
    invite: {key:'invite', path: '/user/share/invite', h5Path: '' },
    //登录
    login: {key:'login', path: '/user/login', h5Path: 'login/sigin'},
    //赛事详情
    gamedetail: {key:'gamedetail', path: '/game/gamedetail', h5Path: '/thirdpartyGames' },
    //safari
    browser: {key:'browser', path: 'browser', h5Path: '' },

  }

/**
 * 登录情况下 走h5逻辑
 */
  export const EnumHasLoginToH5 ={
     h5:{key:'h5',path:'',h5Path:''}
  }


export function useNavigateToPlus() {
  const {token}=useAuth()
    const navigateToPlus=useCallback((
      SysOSType:{
        key:string,
        path:string, //若path 为空，则代表只走h5逻辑
        h5Path:string
      },
      callbackFunc?:()=>void,
      inParams?:{
        method?: string;
        params?: any;
        callback?: string;
        callbackParams?: any;
      }
    )=>{
     if(window._syswm_isNative ){
        let path = token ?  SysOSType.path : EnmuSysOSPath.login.path 
        if(SysOSType.key==EnmuSysOSPath.browser.key  ){
            path=EnmuSysOSPath.browser.path
        }else if(SysOSType.key==EnmuSysOSPath.service.key  ){
          path=EnmuSysOSPath.service.path
        }
        const { method = '' , params = {} , callback= '',callbackParams} = inParams || {};
        if(Object.keys(EnmuSysOSPath).includes(SysOSType.key)){
          platformInvoke({path , method  , params , callback,callbackParams}) 
        }else{
          //注意：未登录情况下代表走原生逻辑，否则走h5逻辑
          if(!token){
            platformInvoke({path , method  , params , callback,callbackParams}) 
          }else{
            callbackFunc &&  callbackFunc()
          }
        }
      }else{
        if(callbackFunc &&  token){
          callbackFunc()
        }else{
          if(SysOSType.key==EnmuSysOSPath.browser.key){
            callbackFunc &&  callbackFunc()
          }else if(SysOSType.key==EnmuSysOSPath.service.key){
            navigateTo(EnmuSysOSPath.service.h5Path);
          }else{
            const path = token ?  SysOSType.h5Path : EnmuSysOSPath.login.h5Path
            navigateTo(path);
          }
        }
      }
    },[token])
  return {
    navigateToPlus
  }
}


