import { apiFetcher, useSWRExpand } from '@/api/api'
import { useEffect, useState } from 'react'

export function useGetActivityDetail(id?: number) {
  const fetcherFuc = () => {
    return apiFetcher(
      {
        path: '/act/api/v1/activityShow/activityDetail',
        type: 'post',
        needToken: false
      },
      {
        arg: {
          id
        }
      }
    )
  }
  const result = useSWRExpand(
    'useGetActivityDetail' + id,
    id ? fetcherFuc : null,
    {
      dedupingInterval: 20 * 1000
    }
  )
  const detail = result?.data?.data
  return {
    ...result,
    detail
  }
}

export function useBannerJump() {
  const [activityId, setActivityId] = useState<any>(0)
  const { mutate, isLoading, detail, error } = useGetActivityDetail(activityId)
  const jumpUrl = (url: string, activityId?: any) => {
    if (url === '/activity/friendInvitatio') {
      setActivityId(Number(activityId))
      return
    }

    if (activityId) {
      setActivityId(Number(activityId))
      return
    }
    const _url: string = `/${url ?? ''}`.replace('//', '/')
    if (_url.includes('/activity/list')) {
      const id = url.replace('/activity/list', '').replace('/', '')
      setActivityId(Number(id))
    } else {
      navigateTo(url)
    }
  }

  useEffect(() => {
    if (detail) {
      const { type, title, appTopicLink } = detail
      const url = `/activityDetails/${type}?activityId=${activityId}&type=${type}&title=${title}&appTopicLink=${appTopicLink}`
      navigateTo(url)
    }
  }, [detail, activityId])

  return {
    jumpUrl,
    isLoading,
    error
  }
}
