

  import React from 'react';
  import Home from '@/views/main/home';
import Main from '@/views/main';
  
  const routes = [
  {
    "name": "aboutHobo",
    "path": "/aboutHobo",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_aboutHobo' */ /* webpackPrefetch: true */ '@/views/aboutHobo')),
    "children": [],
    "customPath": "/aboutHobo"
  },
  {
    "name": "activityDetails",
    "path": "/activityDetails",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_activityDetails' */ /* webpackPrefetch: true */ '@/views/activityDetails')),
    "children": [],
    "customPath": "activityDetails/:type"
  },
  {
    "name": "addPay",
    "path": "/addPay",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_addPay' */ /* webpackPrefetch: true */ '@/views/addPay')),
    "children": [],
    "customPath": "/addPay/:type?",
    "author": true
  },
  {
    "name": "authLogin",
    "path": "/authLogin",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_authLogin' */ /* webpackPrefetch: true */ '@/views/authLogin')),
    "children": [],
    "customPath": "/authLogin"
  },
  {
    "name": "awardCheckinRecords",
    "path": "/awardCheckinRecords",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_awardCheckinRecords' */ /* webpackPrefetch: true */ '@/views/awardCheckinRecords')),
    "children": [],
    "customPath": "/awardCheckinRecords"
  },
  {
    "name": "betOrderRecords",
    "path": "/betOrderRecords",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_betOrderRecords' */ /* webpackPrefetch: true */ '@/views/betOrderRecords')),
    "children": [],
    "customPath": "/betOrderRecords",
    "author": true
  },
  {
    "path": "/deposit/detail",
    "name": "detail",
    "component": React.lazy(() => import('@/views/deposit/detail')),
    "author": true,
    "customPath": "/deposit/detail",
    "isRootRouter": true
  },
  {
    "name": "deposit",
    "path": "/deposit",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_deposit' */ /* webpackPrefetch: true */ '@/views/deposit')),
    "children": [],
    "customPath": "/deposit",
    "author": true
  },
  {
    "path": "/downloadPage/downloadGuide",
    "name": "downloadGuide",
    "component": React.lazy(() => import('@/views/downloadPage/downloadGuide')),
    "customPath": "/downloadPage/downloadGuide",
    "isRootRouter": true
  },
  {
    "name": "downloadPage",
    "path": "/downloadPage",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_downloadPage' */ /* webpackPrefetch: true */ '@/views/downloadPage')),
    "children": [],
    "customPath": "/downloadPage"
  },
  {
    "path": "/downloadPage/setupGuide",
    "name": "setupGuide",
    "component": React.lazy(() => import('@/views/downloadPage/setupGuide')),
    "customPath": "/downloadPage/setupGuide",
    "isRootRouter": true
  },
  {
    "name": "feedback",
    "path": "/feedback",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_feedback' */ /* webpackPrefetch: true */ '@/views/feedback')),
    "children": [],
    "customPath": "/feedback",
    "author": true
  },
  {
    "name": "feedbackDetails",
    "path": "/feedbackDetails",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_feedbackDetails' */ /* webpackPrefetch: true */ '@/views/feedbackDetails')),
    "children": [],
    "customPath": "/feedbackDetails"
  },
  {
    "name": "forgetPassword",
    "path": "/forgetPassword",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_forgetPassword' */ /* webpackPrefetch: true */ '@/views/forgetPassword')),
    "children": [],
    "customPath": "/forgetPassword"
  },
  {
    "path": "/help/helpContact",
    "name": "helpContact",
    "component": React.lazy(() => import('@/views/help/helpContact')),
    "customPath": "/help/helpContact",
    "isRootRouter": true
  },
  {
    "path": "/help/helpDeposit",
    "name": "helpDeposit",
    "component": React.lazy(() => import('@/views/help/helpDeposit')),
    "customPath": "/helpDeposit/:type",
    "defaultRoute": "/helpDeposit/1003",
    "isRootRouter": true
  },
  {
    "path": "/help/helpDetail",
    "name": "helpDetail",
    "component": React.lazy(() => import('@/views/help/helpDetail')),
    "customPath": "/help/helpDetail",
    "isRootRouter": true
  },
  {
    "path": "/help/helpGame",
    "name": "helpGame",
    "component": React.lazy(() => import('@/views/help/helpGame')),
    "customPath": "/help/helpGame",
    "isRootRouter": true
  },
  {
    "path": "/help/helpIntroCoin",
    "name": "helpIntroCoin",
    "component": React.lazy(() => import('@/views/help/helpIntroCoin')),
    "customPath": "/help/helpIntroCoin",
    "isRootRouter": true
  },
  {
    "path": "/help/helpSupport",
    "name": "helpSupport",
    "component": React.lazy(() => import('@/views/help/helpSupport')),
    "customPath": "/help/helpSupport",
    "isRootRouter": true
  },
  {
    "path": "/help/helpTransfer",
    "name": "helpTransfer",
    "component": React.lazy(() => import('@/views/help/helpTransfer')),
    "customPath": "/help/helpTransfer",
    "isRootRouter": true
  },
  {
    "path": "/help/helpWithdaralUsdt",
    "name": "helpWithdaralUsdt",
    "component": React.lazy(() => import('@/views/help/helpWithdaralUsdt')),
    "customPath": "/help/helpWithdaralUsdt",
    "isRootRouter": true
  },
  {
    "path": "/help/helpWithdrawal",
    "name": "helpWithdrawal",
    "component": React.lazy(() => import('@/views/help/helpWithdrawal')),
    "customPath": "/help/helpWithdrawal",
    "isRootRouter": true
  },
  {
    "name": "help",
    "path": "/help",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_help' */ /* webpackPrefetch: true */ '@/views/help')),
    "children": [],
    "customPath": "/help"
  },
  {
    "name": "idVerification",
    "path": "/idVerification",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_idVerification' */ /* webpackPrefetch: true */ '@/views/idVerification')),
    "children": [],
    "customPath": "/idVerification",
    "author": true
  },
  {
    "name": "joinUs",
    "path": "/joinUs",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_joinUs' */ /* webpackPrefetch: true */ '@/views/joinUs')),
    "children": [],
    "customPath": "/joinUs"
  },
  {
    "name": "login",
    "path": "/login",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_login' */ /* webpackPrefetch: true */ '@/views/login')),
    "children": [],
    "customPath": "/login/:type?"
  },
  {
    "name": "lotteryRecord",
    "path": "/lotteryRecord",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_lotteryRecord' */ /* webpackPrefetch: true */ '@/views/lotteryRecord')),
    "children": [],
    "customPath": "/lotteryRecord"
  },
  {
    "name": "main",
    "path": "/main",
    "component": Main,
    "children": [
      {
        "path": "/main/deals",
        "name": "deals",
        "component": React.lazy(() => import('@/views/main/deals')),
        "customPath": "/deals/:type"
      },
      {
        "path": "/main/home",
        "name": "home",
        "lazyLoad": false,
        "component": Home,
        "customPath": "home"
      },
      {
        "path": "/main/myServices",
        "name": "customerService",
        "component": React.lazy(() => import('@/views/main/myServices')),
        "customPath": "customerService"
      },
      {
        "path": "/main/profile",
        "name": "profile",
        "component": React.lazy(() => import('@/views/main/profile')),
        "customPath": "profile"
      },
      {
        "path": "/main/promotion",
        "name": "promotion",
        "component": React.lazy(() => import('@/views/main/promotion')),
        "customPath": "promotion"
      }
    ],
    "customPath": "/",
    "defaultRoute": "/home"
  },
  {
    "name": "management",
    "path": "/management",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_management' */ /* webpackPrefetch: true */ '@/views/management')),
    "children": [],
    "customPath": "/management",
    "author": true
  },
  {
    "name": "messageCenter",
    "path": "/messageCenter",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_messageCenter' */ /* webpackPrefetch: true */ '@/views/messageCenter')),
    "children": [],
    "customPath": "/messageCenter/:messageType?/:type?",
    "author": true
  },
  {
    "name": "myFeedback",
    "path": "/myFeedback",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_myFeedback' */ /* webpackPrefetch: true */ '@/views/myFeedback')),
    "children": [],
    "customPath": "/myFeedback",
    "author": true
  },
  {
    "name": "myWallet",
    "path": "/myWallet",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_myWallet' */ /* webpackPrefetch: true */ '@/views/myWallet')),
    "children": [],
    "customPath": "/myWallet",
    "author": true
  },
  {
    "name": "normalRecords",
    "path": "/normalRecords",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_normalRecords' */ /* webpackPrefetch: true */ '@/views/normalRecords')),
    "children": [],
    "customPath": "/normalRecords"
  },
  {
    "name": "orderTransactionDetail",
    "path": "/orderTransactionDetail",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_orderTransactionDetail' */ /* webpackPrefetch: true */ '@/views/orderTransactionDetail')),
    "children": [],
    "customPath": "/orderTransactionDetail",
    "author": true
  },
  {
    "name": "orderTransactions",
    "path": "/orderTransactions",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_orderTransactions' */ /* webpackPrefetch: true */ '@/views/orderTransactions')),
    "children": [],
    "customPath": "/orderTransactions",
    "author": true
  },
  {
    "path": "/personInfo/bindEmail",
    "name": "bindEmail",
    "component": React.lazy(() => import('@/views/personInfo/bindEmail')),
    "author": true,
    "customPath": "/personInfo/bindEmail",
    "isRootRouter": true
  },
  {
    "path": "/personInfo/bindName",
    "name": "bindName",
    "component": React.lazy(() => import('@/views/personInfo/bindName')),
    "author": true,
    "customPath": "/personInfo/bindName",
    "isRootRouter": true
  },
  {
    "path": "/personInfo/bindPhone",
    "name": "bindPhone",
    "component": React.lazy(() => import('@/views/personInfo/bindPhone')),
    "author": true,
    "customPath": "/personInfo/bindPhone",
    "isRootRouter": true
  },
  {
    "name": "personInfo",
    "path": "/personInfo",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_personInfo' */ /* webpackPrefetch: true */ '@/views/personInfo')),
    "children": [],
    "customPath": "/personInfo",
    "author": true
  },
  {
    "name": "privacyAgreement",
    "path": "/privacyAgreement",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_privacyAgreement' */ /* webpackPrefetch: true */ '@/views/privacyAgreement')),
    "children": [],
    "customPath": "/privacyAgreement/:type?"
  },
  {
    "name": "settings",
    "path": "/settings",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_settings' */ /* webpackPrefetch: true */ '@/views/settings')),
    "children": [],
    "customPath": "/settings"
  },
  {
    "name": "shareRecords",
    "path": "/shareRecords",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_shareRecords' */ /* webpackPrefetch: true */ '@/views/shareRecords')),
    "children": [],
    "customPath": "/shareRecords"
  },
  {
    "name": "slotMachine",
    "path": "/slotMachine",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_slotMachine' */ /* webpackPrefetch: true */ '@/views/slotMachine')),
    "children": [],
    "customPath": "/slotMachine/:type?",
    "author": true
  },
  {
    "name": "thirdpartH5",
    "path": "/thirdpartH5",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_thirdpartH5' */ /* webpackPrefetch: true */ '@/views/thirdpartH5')),
    "children": [],
    "customPath": "/thirdpartH5"
  },
  {
    "name": "thirdpartyGames",
    "path": "/thirdpartyGames",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_thirdpartyGames' */ /* webpackPrefetch: true */ '@/views/thirdpartyGames')),
    "children": [],
    "customPath": "/thirdpartyGames",
    "author": true
  },
  {
    "name": "topGiftExchangeRecords",
    "path": "/topGiftExchangeRecords",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_topGiftExchangeRecords' */ /* webpackPrefetch: true */ '@/views/topGiftExchangeRecords')),
    "children": [],
    "customPath": "/topGiftExchangeRecords",
    "author": true
  },
  {
    "name": "transfer",
    "path": "/transfer",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_transfer' */ /* webpackPrefetch: true */ '@/views/transfer')),
    "children": [],
    "customPath": "/transfer",
    "author": true
  },
  {
    "name": "txnDetails",
    "path": "/txnDetails",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_txnDetails' */ /* webpackPrefetch: true */ '@/views/txnDetails')),
    "children": [],
    "customPath": "/txnDetails",
    "author": true
  },
  {
    "name": "updatePassword",
    "path": "/updatePassword",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_updatePassword' */ /* webpackPrefetch: true */ '@/views/updatePassword')),
    "children": [],
    "customPath": "/updatePassword",
    "author": true
  },
  {
    "name": "vip",
    "path": "/vip",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_vip' */ /* webpackPrefetch: true */ '@/views/vip')),
    "children": [],
    "customPath": "/vip",
    "author": true
  },
  {
    "name": "walletExchange",
    "path": "/walletExchange",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_walletExchange' */ /* webpackPrefetch: true */ '@/views/walletExchange')),
    "children": [],
    "customPath": "/walletExchange",
    "author": true
  },
  {
    "name": "welfareRecords",
    "path": "/welfareRecords",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_welfareRecords' */ /* webpackPrefetch: true */ '@/views/welfareRecords')),
    "children": [],
    "customPath": "/welfareRecords",
    "author": true
  },
  {
    "name": "withdraw",
    "path": "/withdraw",
    "component": React.lazy(() => import(/* webpackChunkName: 'router_withdraw' */ /* webpackPrefetch: true */ '@/views/withdraw')),
    "children": [],
    "customPath": "/withdraw",
    "author": true
  },
  {
    "path": "/withdraw/withdrawDetail",
    "name": "withdrawDetail",
    "component": React.lazy(() => import('@/views/withdraw/withdrawDetail')),
    "author": true,
    "customPath": "/withdrawDetail",
    "isRootRouter": true
  }
]

  
  export default routes;
  