import React, { ReactNode, forwardRef } from 'react'
import './index.scss'
import SpriteContainer from '../spriteContainer'
import { useTheme } from '@/provides/themeProvider'

interface HeaderUIProps {
  title: string
  rightNode?: ReactNode
  showBack?: boolean
  onClickBack?: () => any
  className?: string
}

const HeaderUI = (
  { title, rightNode, showBack, onClickBack, className }: HeaderUIProps,
  ref
) => {

  if (window._syswm_isNative) {
    return null
  }

  const { theme } = useTheme();

  return (
    <div className={'header_ui' + ' ' + className} ref={ref}>
      <div className={'header_view'}>
        <div className={'left_view'}>
          {showBack && (

            <SpriteContainer
              onClick={() => onClickBack?.()}
              name={theme === 'night' ? 'icon_return_left_night' : 'icon_return_left_day'}
            ></SpriteContainer>
          )}
        </div>
        <div className={'center_view'}>
          <div className={'header_item_text'}>{title}</div>
        </div>
        <div className={'right_view'}>{rightNode}</div>
      </div>
    </div>
  )
}

export default forwardRef(HeaderUI)
