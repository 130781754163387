import CryptoJS from 'crypto-js';

// 示例密钥和 IV（初始化向量）
const secretKey:string = process.env.REACT_APP_SECRET_KEY || ''; // 生成随机密钥
const iv:string = process.env.REACT_APP_SECRET_IV || ''; // 生成随机 IV

// AES 加密函数
export function aesEncrypt(data: string, type: 'string' | 'hex' = 'string', _secretKey?: string, _iv?: string ): string {
  
  const key = CryptoJS.enc.Utf8.parse(_secretKey ? _secretKey : secretKey);
  const ivBytes = CryptoJS.enc.Utf8.parse(_iv ? _iv : iv);
  const encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: ivBytes,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  if(type === 'string') {
    return encrypted.toString();
  }else {
    return encrypted.ciphertext.toString(CryptoJS.enc.Hex)
  }
}

// AES 解密函数
export function aesDecrypt(encryptedData: string): string {
  const key = CryptoJS.enc.Utf8.parse(secretKey);
  const ivBytes = CryptoJS.enc.Utf8.parse(iv);
  const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
    iv: ivBytes,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}
