import { Popup } from 'antd-mobile'
import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import { useBankCardList } from '@/commonApi'
import LoadingUI from '../loadingUI'
import zhPinyin from './zh_pinyin.json'
import { wait } from '@/commonHooks/nextTick'
import SpriteContainer from '../spriteContainer'
interface PopupBankNameProps {
  visible?: boolean
  onClose?: () => void
  onConfirm?: (v: { bankCode: string; bankName: string; icon: string }) => void
}

const PopupBankName = (props: PopupBankNameProps) => {
  const { visible, onClose, onConfirm } = props
  const { data: { data } = {}, isLoading } = useBankCardList()
  const contentRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const bodyRef = useRef<HTMLDivElement>(null)
  const [chooseData, setChooseData] = useState<any>()

  const [value, setValue] = useState('')

  useEffect(() => {
    wait(20).then(() => {
      if (!contentRef.current || !headerRef.current || !bodyRef.current) {
        return
      }
      const height =
        contentRef.current.getBoundingClientRect().height -
        headerRef.current.getBoundingClientRect().height
      bodyRef.current.style.height = height + 'px'
      setValue('')
    })
  }, [visible])

  const [searchList, setSearchList] = useState(data || [])

  useEffect(() => {
    if (value) {
      // 将用户输入的内容转化为拼音并进行搜索
      const searchValue = value.toUpperCase()
      const filteredList = data?.filter?.((item) => {
        // 查找银行名称的拼音匹配
        const bankName = item.bankName
        const pinyinMatch = zhPinyin.find(
          (p) => bankName.includes(p.text) && p.pinyin.startsWith(searchValue)
        )
        return bankName.includes(value) || pinyinMatch
      })
      setSearchList(filteredList)
    } else {
      setSearchList(data)
    }
  }, [value, data])

  return (
    <Popup
      visible={visible}
      onMaskClick={() => onClose?.()}
    >
      <div className={styles.content} ref={contentRef}>
        <div className={styles.header} ref={headerRef}>
          <div className={styles.top}>
            {/* <span onClick={()=> onClose?.()}>取消</span> */}
            <span>请选择所属银行</span>
          </div>
          <div className={styles.search}>
            <input
              type="text"
              placeholder={'请输入银行名称'}
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
              }}
            />

            <SpriteContainer name='icon_search_20_day'></SpriteContainer>
          </div>
        </div>
        <div className={styles.body} ref={bodyRef}>
          <div className={styles.scroll}>
            <LoadingUI
              fullSceen={false}
              loading={isLoading}
              className={styles.loading}
              data={data}
            >
              {searchList?.map?.((v, index) => {
                return (
                  <div
                    className={styles.item}
                    key={index}
                    onClick={() => {
                      //onConfirm?.(v)
                      // onClose?.()
                      setChooseData(v)
                    }}
                  >
                    <img src={v.icon} loading={'lazy'} />
                    <span>{v.bankName}</span>
                    {chooseData === v && <SpriteContainer name='icon_select' ></SpriteContainer>}
                  </div>
                )
              })}
            </LoadingUI>
            <div className={styles.button_group}>
              <button onClick={() => {
                onClose?.()
              }}>取消</button>
              <button onClick={() => {
                if (chooseData) {
                  onConfirm?.(chooseData)
                }
                onClose?.()
              }}>确定</button>
            </div>

          </div>

        </div>
      </div>
    </Popup>
  )
}

export default PopupBankName
