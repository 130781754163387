import { SWRCenter } from '@/api/api'
import { nextTick } from '@/commonHooks/nextTick'
import localCache from '@/utils/cache'
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect
} from 'react'
import { useNavigate } from 'react-router'

interface CustomConfig {
  //我的界面 佣金金额展示
  myProfileEye: boolean
}

interface AuthContextType {
  token: string | null
  login: (data: any) => void
  logout: () => void
  data: any | null
  customConfig: CustomConfig
  setCustomConfig: React.Dispatch<React.SetStateAction<CustomConfig>>,
  isLogin: boolean
}

const AuthContext = createContext<AuthContextType | null>(null)

interface AuthProviderProps {
  children: ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [token, setToken] = useState<string | null>(
    window._syswm_token  || localCache.get('authToken',undefined)
  )
  const [ isLogin , setIsLogin] = useState<any>(!!window._syswm_token || !!localCache.get('authToken',undefined))
  const [customConfig, setCustomConfig] = useState<CustomConfig>({
    myProfileEye: false
  })
  const [loginData, setLoginData] = useState<any | null>(null)
  const navigate = useNavigate()

  const login = (data: any) => {
    setToken(data.token)
    localCache.set('authToken', data.token)
    setLoginData(data)
    setIsLogin(true)
  }

  const logout = async () => {
    setToken(null)
    setCustomConfig({
      ...customConfig,
      myProfileEye: false
    })
    SWRCenter.getInstance().randomKey()
    localCache.remove('authToken')
    setIsLogin(false)
    await nextTick()
    navigate('/login')
  }

  return (
    <AuthContext.Provider
      value={{
        data: loginData,
        token,
        login,
        logout,
        customConfig,
        setCustomConfig,
        isLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
