import React, { createContext, useContext, useEffect, useRef } from 'react'
import { useGlobalInputBlur, useScrollToTop } from './commonHooks/resizeHeight'
import RouteCenter from './router/routeCenter'
import { usePreInfo, useUserInfo } from './commonApi'
import { useCommonErrorCode } from './commonHooks/useCommonErrorCode'
import { useSafeSwitch } from './commonHooks/useSafeSwitch'
import { useTranslation } from 'react-i18next'
interface LayoutContextType {
  /**折叠左侧112*/
  appElement: React.RefObject<HTMLDivElement>
}
const AppContext = createContext<LayoutContextType | null>(null)

function App() {
  /**
   *监听接口错误码
   */
  useCommonErrorCode()
  /**
   * 适配部分移动设备 软键盘弹出 界面没有回滚bug
   */
  useGlobalInputBlur()
  /**预加载用户信息 */
  useUserInfo()

  useScrollToTop()

  usePreInfo()

  useSafeSwitch()

  const {t} = useTranslation('platform') 
  useEffect(() => {
    // 动态设置页面标题
    document.title = t('platformName');
  }, []);

  const appRef = useRef<HTMLDivElement>(null)

  // useFirstEnter()

  return (
    <AppContext.Provider
      value={{
        appElement: appRef
      }}
    >
      <div className="App" ref={appRef}>
        <RouteCenter />
      </div>
    </AppContext.Provider>
  )
}

export default App

export const useApp = () => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('useApp must be used within an AppContext')
  }
  return context
}
