import { Fingerprint } from '@/compoments/desensitzeData'
export enum CardName {
  General = 'bank', // 普通提款
  MPay = 'mpay', // MPay提款
  Alipay = 'alipay', // 支付宝
  Ermb = 'ermb', // 数字人民币
  Ebpay = 'ebpay', // EBPay
  Pay988 = 'pay988', // 998钱包
  FPay = 'fpay', // FPAY钱包
  Okpay = 'okpay', // okpay
  Topay = 'topay', // topay
  Gopay = 'gopay', // gopay
  Pay808 = 'pay808', // 808钱包
  Ipay = 'ipay', // Ipay
  JDPay = 'jdpay', // JD钱包
  Ccoin = 'cbpay', // C币钱包
  Kcoin = 'kdpay', // K豆钱包
  QianNeng = 'qiannengpay', // 钱能钱包
  TGPAY = 'tgpay', // TG钱包
  USDT = 'usdt', // usdt
  WANPay = 'wanbipay', // 万币
  Pay365 = 'pay365', // 365Pay
  ABPay = 'abpay', // ABPay
  WePay = 'wepay' // WePay
}

export enum CardType {
  General = '2001', // 普通
  MPay = '1006', // MPay
  Alipay = '1002', // 支付宝
  Ermb = '1004', // 数字人民币
  Ebpay = '1018', // EBPay
  Pay988 = '1021', // 988钱包
  FPay = '1027', // FPAY钱包
  Okpay = '1028', // okpay
  Topay = '1029', // topay
  Gopay = '1030', // gopay
  Pay808 = '1031', // 808钱包
  Ipay = '1008', // Ipay
  JDPay = '1022', // JD钱包
  Ccoin = '1023', // C币钱包
  Kcoin = '1024', // K豆钱包
  QianNeng = '1025', // 钱能钱包
  TGPAY = '1026', // TG钱包
  USDT = 'usdt', // usdt
  WanCoin = '1033', // 万币
  Pay365 = '1034', // 365Pay
  ABPay = '1035', // ABPay
  WePay = '1036' // WePay
}

// 通用配置生成函数：根据传入的类型、名称、限制和路径来生成配置对象
const createCardConfig = (
  type: CardType,
  name: CardName,
  limit: number,
  path: string,
  delPath: string,
  zhName: string,
  fingerprint: Fingerprint
) => ({
  limit, // 每种卡片的限制数量
  name, // 卡片的名称，对应 CardName 枚举
  api: {
    path, // API 路径
    type: CardName.General === name ? 'get' : 'post' // 请求类型，目前默认为 'post'，可以根据需要扩展
  },
  delApi: {
    delPath,
    type: 'post'
  },
  zhName,
  fingerprint
})

// 使用 Record 将 CardType 枚举值映射到具体的配置对象
export const BankCardMap: Record<
  CardType,
  ReturnType<typeof createCardConfig>
> = {
  [CardType.General]: createCardConfig(
    CardType.General,
    CardName.General,
    10,
    '/fd/api/v1/bankCard/queryMemberBanksInfoList',
    '/fd/api/v1/bankCard/deleteMemberBanks',
    '银行卡',
    Fingerprint.XsS2
  ), // 普通提款
  [CardType.MPay]: createCardConfig(
    CardType.MPay,
    CardName.MPay,
    3,
    '/fd/api/v1/mpay/list',
    '/fd/api/v1/vCurrency/delete',
    'MPay',
    Fingerprint.XsS35
  ), // MPay提款
  [CardType.Alipay]: createCardConfig(
    CardType.Alipay,
    CardName.Alipay,
    3,
    '/fd/api/v1/alipay/list',
    '/fd/api/v1/alipay/delete',
    '支付宝',
    Fingerprint.XsS37
  ), // 支付宝
  [CardType.Ermb]: createCardConfig(
    CardType.Ermb,
    CardName.Ermb,
    3,
    '/fd/api/v1/ermb/list',
    '/fd/api/v1/ermb/delete',
    '数字人民币',
    Fingerprint.XsS38
  ), // 数字人民币
  [CardType.Ebpay]: createCardConfig(
    CardType.Ebpay,
    CardName.Ebpay,
    3,
    '/fd/api/v1/ebpay/list',
    '/fd/api/v1/ebpay/delete',
    'EBPay',
    Fingerprint.XsS35
  ), // EBPay
  [CardType.Pay988]: createCardConfig(
    CardType.Pay988,
    CardName.Pay988,
    3,
    '/fd/api/v1/pay988/list',
    '/fd/api/v1/pay988/delete',
    '988钱包',
    Fingerprint.XsS35
  ), // 988钱包
  [CardType.FPay]: createCardConfig(
    CardType.FPay,
    CardName.FPay,
    3,
    '/fd/api/v1/fpay/list',
    '/fd/api/v1/fpay/delete',
    'FPAY',
    Fingerprint.XsS35
  ), // FPAY钱包
  [CardType.Okpay]: createCardConfig(
    CardType.Okpay,
    CardName.Okpay,
    3,
    '/fd/api/v1/okpay/list',
    '/fd/api/v1/topay/delete',
    'OKPAY',
    Fingerprint.XsS35
  ), // okpay
  [CardType.Topay]: createCardConfig(
    CardType.Topay,
    CardName.Topay,
    3,
    '/fd/api/v1/topay/list',
    '/fd/api/v1/okpay/delete',
    'TOPAY',
    Fingerprint.XsS35
  ), // topay
  [CardType.Gopay]: createCardConfig(
    CardType.Gopay,
    CardName.Gopay,
    3,
    '/fd/api/v1/gopay/list',
    '/fd/api/v1/gopay/delete',
    'GOPAY',
    Fingerprint.XsS35
  ), // gopay
  [CardType.Pay808]: createCardConfig(
    CardType.Pay808,
    CardName.Pay808,
    3,
    '/fd/api/v1/pay808/list',
    '/fd/api/v1/pay808/delete',
    '808钱包',
    Fingerprint.XsS35
  ), // 808钱包
  [CardType.Ipay]: createCardConfig(
    CardType.Ipay,
    CardName.Ipay,
    3,
    '/fd/api/v1/ipay/list',
    '/fd/api/v1/ipay/delete',
    'IPay',
    Fingerprint.XsS35
  ), // Ipay
  [CardType.JDPay]: createCardConfig(
    CardType.JDPay,
    CardName.JDPay,
    3,
    '/fd/api/v1/jdpay/list',
    '/fd/api/v1/jdpay/delete',
    'JD钱包',
    Fingerprint.XsS35
  ), // JD钱包
  [CardType.Ccoin]: createCardConfig(
    CardType.Ccoin,
    CardName.Ccoin,
    3,
    '/fd/api/v1/cbpay/list',
    '/fd/api/v1/cbpay/delete',
    'C币钱包',
    Fingerprint.XsS35
  ), // C币钱包
  [CardType.Kcoin]: createCardConfig(
    CardType.Kcoin,
    CardName.Kcoin,
    3,
    '/fd/api/v1/kdpay/list',
    '/fd/api/v1/kdpay/delete',
    'K豆钱包',
    Fingerprint.XsS35
  ), // K豆钱包
  [CardType.QianNeng]: createCardConfig(
    CardType.QianNeng,
    CardName.QianNeng,
    3,
    '/fd/api/v1/qiannengpay/list',
    '/fd/api/v1/qiannengpay/delete',
    '钱能钱包',
    Fingerprint.XsS35
  ), // 钱能钱包
  [CardType.TGPAY]: createCardConfig(
    CardType.TGPAY,
    CardName.TGPAY,
    3,
    '/fd/api/v1/tgpay/list',
    '/fd/api/v1/tgpay/delete',
    'TG钱包',
    Fingerprint.XsS35
  ), // TG钱包
  [CardType.USDT]: createCardConfig(
    CardType.USDT,
    CardName.USDT,
    3,
    '/fd/api/v1/vCurrency/list',
    '/fd/api/v1/vCurrency/delete',
    '虚拟币',
    Fingerprint.XsS10
  ), // USDT

  [CardType.WanCoin]: createCardConfig(
    CardType.WanCoin,
    CardName.WANPay,
    3,
    '/fd/api/v1/wanbipay/list',
    '/fd/api/v1/wanbipay/delete',
    '万币钱包',
    Fingerprint.XsS35
  ), // 万币
  [CardType.Pay365]: createCardConfig(
    CardType.Pay365,
    CardName.Pay365,
    3,
    '/fd/api/v1/pay365/list',
    '/fd/api/v1/pay365/delete',
    '365Pay',
    Fingerprint.XsS35
  ), // Pay365
  [CardType.ABPay]: createCardConfig(
    CardType.ABPay,
    CardName.ABPay,
    3,
    '/fd/api/v1/abpay/list',
    '/fd/api/v1/abpay/delete',
    'ABPay',
    Fingerprint.XsS35
  ), // ABPay
  [CardType.WePay]: createCardConfig(
    CardType.WePay,
    CardName.WePay,
    3,
    '/fd/api/v1/wepay/list',
    '/fd/api/v1/wepay/delete',
    'WePay',
    Fingerprint.XsS35
  )
}

// 字段映射配置，根据不同的 CardType 返回对应的数据字段
const fieldMappings = {
  [CardType.General]: {
    name: 'bankName', // 返回银行名称
    number: 'cardNumDesensitization', // 返回卡号（脱敏处理）
    image: 'icon', // 返回图标字段名
    useLocalImage: false, // 是否使用本地图像,
    localTitle: '请选择银行卡',
    localRightTitle: '管理账户'
  },
  [CardType.Ebpay]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_ebpay', // Base64 编码的图像
    localTitle: '请选择EBPay',
    localRightTitle: '管理账户'
  },
  [CardType.Ermb]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_szrmb', // Base64 编码的图像
    localTitle: '请选择数字人民币',
    localRightTitle: '管理账户'
  },
  [CardType.Topay]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_toppay', // Base64 编码的图像
    localTitle: '请选择TOPAY钱包',
    localRightTitle: '管理账户'
  },
  [CardType.Pay988]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_988', // Base64 编码的图像
    localTitle: '请选择988钱包',
    localRightTitle: '管理账户'
  },
  [CardType.FPay]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_fpay', // Base64 编码的图像
    localTitle: '请选择FPAY钱包',
    localRightTitle: '管理账户'
  },
  [CardType.MPay]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_mpay', // Base64 编码的图像
    localTitle: '请选择MPAY钱包',
    localRightTitle: '管理账户'
  },
  [CardType.Gopay]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_gopay', // Base64 编码的图像
    localTitle: '请选择GOPAY钱包',
    localRightTitle: '管理账户'
  },
  [CardType.Pay808]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_808', // Base64 编码的图像
    localTitle: '请选择808钱包',
    localRightTitle: '管理账户'
  },
  [CardType.Ipay]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_ipay', // Base64 编码的图像
    localTitle: '请选择Ipay钱包',
    localRightTitle: '管理账户'
  },
  [CardType.JDPay]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_jdpay', // Base64 编码的图像
    localTitle: '请选择JD钱包',
    localRightTitle: '管理账户'
  },
  [CardType.Ccoin]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_ccoin', // Base64 编码的图像
    localTitle: '请选择C币钱包',
    localRightTitle: '管理账户'
  },
  [CardType.Kcoin]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_kdpay', // Base64 编码的图像
    localTitle: '请选择K豆钱包',
    localRightTitle: '管理账户'
  },
  [CardType.Okpay]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_okpay', // Base64 编码的图像
    localTitle: '请选择OKPAY钱包',
    localRightTitle: '管理账户'
  },
  [CardType.QianNeng]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_qnpay', // Base64 编码的图像
    localTitle: '请选择钱能钱包',
    localRightTitle: '管理账户'
  },
  [CardType.TGPAY]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_tgpay', // Base64 编码的图像
    localTitle: '请选TG钱包',
    localRightTitle: '管理账户'
  },
  [CardType.USDT]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_usdtsm', // Base64 编码的图像
    localTitle: '请选择USDT钱包',
    localRightTitle: '管理账户'
  },
  [CardType.Alipay]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_zfb', // Base64 编码的图像
    localTitle: '请选择支付宝',
    localRightTitle: '管理账户'
  },
  [CardType.WanCoin]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_wbnb', // Base64 编码的图像
    localTitle: '请选择万币钱包',
    localRightTitle: '管理账户'
  },
  [CardType.Pay365]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_365pay', // Base64 编码的图像
    localTitle: '请选择365Pay',
    localRightTitle: '管理账户'
  },
  [CardType.ABPay]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_abpay', // Base64 编码的图像
    localTitle: '请选择ABPay',
    localRightTitle: '管理账户'
  },

  [CardType.WePay]: {
    name: 'alias', // 返回别名
    number: 'address', // 返回地址
    image: '', // 这里为空表示不从数据中获取图像
    useLocalImage: true, // 表示使用本地图像（Base64）
    localImageBase64: 'icon_wepay', // Base64 编码的图像
    localTitle: '请选择WePay',
    localRightTitle: '管理账户'
  }
  // 如果有更多的 CardType，可以在这里继续添加
}

// 通用的字段获取函数，根据 type 和字段名获取对应的数据
const getField = (
  data: any,
  type: CardType,
  field: keyof (typeof fieldMappings)[CardType.General]
) => {
  const mapping: any = fieldMappings[type] // 获取对应的字段映射配置
  return mapping && mapping[field] ? data?.[mapping?.[field]] : '' // 根据映射配置获取对应字段的值
}

// 获取卡片名称
export const getCardName = (data: any, type: CardType) =>
  getField(data, type, 'name')

// 获取卡片号码
export const getCardNumber = (data: any, type: CardType) =>
  getField(data, type, 'number')

// 获取卡片图像
export const getCardImage = (data: any, type: CardType) => {
  const mapping: any = fieldMappings[type]
  if (mapping?.useLocalImage && mapping.localImageBase64) {
    // 如果配置中指定使用本地图像，且有 Base64 图像，则直接返回 Base64 图像
    return mapping.localImageBase64
  }
  // 否则使用数据中的图像字段
  return getField(data, type, 'image')
}

// 获取title
export const getCardTitle = (type: CardType) => {
  const mapping = fieldMappings[type]
  return mapping?.localTitle
}

// 获取Righttitle
export const getCardRightTitle = (type: CardType) => {
  const mapping = fieldMappings[type]
  return mapping?.localRightTitle
}

//获取脱敏字段
export const getFingerprint = (type: CardType) => {
  return BankCardMap?.[type]?.fingerprint
}

//获取字段

export const getNumberFiled = (type: CardType) => {
  return fieldMappings[type]?.number
}
