import React, { useEffect, useMemo, useRef, useState } from 'react'
import DialogConfirm from '../dialogConfirm'
import styles from './index.module.scss'
import { useCountryArea } from '@/commonApi'
import localCache from '@/utils/cache'
import LoadingUI from '../loadingUI'
import { nextTick } from '@/commonHooks/nextTick'
import SpriteContainer from '../spriteContainer'

interface DialogAreacodeProps {
  visible: boolean
  onClose?: () => void
  onConfirm?: (v: any) => void
}

const DialogAreacode = (props: DialogAreacodeProps) => {
  const {
    data: areaData,
    isLoading,
    error,
    mutate,
    isValidating
  } = useCountryArea()
  const { data = {} } = areaData || {}
  const [areaCode, setAreaCode] = useState<any>()
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const itemRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!data?.length) {
      return
    }
    let area = localCache.get('area')
    if (!area) {
      area = data[0]
      localCache.set('area', area)
    }
    setAreaCode(area)
  }, [data])

  const currentIndex = useMemo(() => {
    return data?.findIndex?.((v) => {
      return v?.areaCode === areaCode?.areaCode
    })
  }, [areaCode, data])

  const firstScroll = useRef(false)

  useEffect(() => {
    if (firstScroll.current || !props.visible) {
      return
    }
    if (!data || !areaCode || !currentIndex || currentIndex < 3) {
      return
    }
    nextTick().then(() => {
      const rect = itemRef.current?.getBoundingClientRect()
      if (!rect) {
        return
      }
      const height = rect.height
      scrollRef.current?.scrollTo({
        top: height * currentIndex,
        behavior: 'smooth'
      })
      firstScroll.current = true
    })
  }, [currentIndex, data, areaCode, props])

  return (
    <DialogConfirm
      visible={props.visible}
      cancelText={'取消'}
      confirmText={'确定'}
      bodyClassName={styles.dialog_areacode}
      content={
        <div className={styles.content}>
          <div className={styles.header}>
            <h1>选择国家</h1>
          </div>
          <div className={styles.body}>
            <div className={styles.scroll} ref={scrollRef}>
              <LoadingUI
                loading={isLoading || isValidating}
                data={data}
                error={error}
                retryFunc={() => {
                  mutate()
                }}
                fullSceen={false}
              >
                {data?.map?.((v, index) => {
                  return (
                    <div
                      key={index}
                      className={styles.item}
                      ref={index === 0 ? itemRef : undefined}
                      onClick={() => {
                        setAreaCode(v)
                      }}
                    >
                      <div className={styles.area_code}>
                        <span>{v.areaCode}</span>
                      </div>

                      <div className={styles.area_name}>
                        <span>{v.areaName}</span>
                      </div>

                      {currentIndex === index && (
                        <SpriteContainer name='icon_select' className={styles.icon}/>
                      )}
                    </div>
                  )
                })}
              </LoadingUI>
            </div>
          </div>
        </div>
      }
      onClose={() => {
        props?.onClose?.()
        firstScroll.current = false
      }}
      onCancle={() => {
        props?.onClose?.()
        firstScroll.current = false
      }}
      onSubmit={async () => {
        if (data && currentIndex !== undefined) {
          localCache.set('area', data[currentIndex])
        }
        firstScroll.current = false
        props?.onConfirm?.(areaCode)
      }}
    />
  )
}

export default DialogAreacode
