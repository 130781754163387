import React, { createContext, useContext, useEffect, useState } from 'react'
import styles from './index.module.scss'
import Banner from '@/views/main/components/banner'
import Menu from '@/views/main/components/menu'
import { useHomeData } from './api'
import { nextTick } from '@/commonHooks/nextTick'
import Games from './compoments/games'
import DownLoad from './compoments/download'
import DialogDownload from './compoments/dialogDownload'
interface HomeContextType {
  hideDownLoad: boolean
}

const HomeContext = createContext<HomeContextType>({
  hideDownLoad: false
})

let hide_downLoad = false
const Home = () => {
  useHomeData()
  const [hideDownLoad, setHideDownLoad] = useState(hide_downLoad)
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    const state = window.history.state
    if (state?.usr?.scrollY) {
      nextTick().then(() => {
        window.scroll({
          top: state.usr.scrollY,
          behavior: 'smooth'
        })
        state.usr.scrollY = 0
        window.history.replaceState({ ...state }, '')
      })
    }
  }, [])
  return (
    <HomeContext.Provider
      value={{
        hideDownLoad
      }}
    >
      <div className={styles.home}>
        <DownLoad
          hideDownLoadDom={hideDownLoad}
          onClickHideDownLoad={() => {
            setHideDownLoad(true)
            hide_downLoad = true
          }}
          openDownload={() => {
            setVisible(true)
          }}
        />
        <Banner />
        <Menu />
        <Games />
        <DialogDownload
          visible={visible}
          onClose={() => {
            setVisible(false)
          }}
        />
      </div>
    </HomeContext.Provider>
  )
}

export default Home

export const useHome = () => {
  const context = useContext(HomeContext)
  if (!context) {
    throw new Error('useHome must be used within an HomeContext')
  }
  return context
}
