import { List } from 'antd-mobile'
import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import { Controller, useFormContext } from 'react-hook-form'
import DialogAreacode from '@/compoments/dialogAreacode'
import localCache from '@/utils/cache'
import { useCountryArea } from '@/commonApi'
import classNames from 'classnames'
import SpriteContainer from '@/compoments/spriteContainer'

/**
 * PhoneItemProps 接口定义
 */
interface PhoneItemProps {
  /**
   * 输入框的名称，用于表单字段绑定
   */
  name: string

  /**
   * 列表项的标题，显示在输入框前
   */
  title?: string

  /**
   * 自定义样式类名，用于自定义样式
   */
  className?: string

  onChangeFocus?: (v: any) => void

  /**
   * 输入框的占位符文本，当输入框为空时显示
   */
  placeholder?: string

  areaCode?: string
  maxLength?: number
}

const PhoneItem = (props: PhoneItemProps) => {
  const {
    name,
    title,
    placeholder,
    className,
    maxLength = 15,
    onChangeFocus
  } = props
  const { control } = useFormContext() // 使用useFormContext获取control对象
  const [visible, setVisible] = useState(false)
  const [area, setArea] = useState(localCache.get('area'))
  const { data: areaData } = useCountryArea()
  const onChangeRef = useRef<any>(null)
  useEffect(() => {
    if (onChangeRef.current) {
      const { value, onChange } = onChangeRef.current
      onChange({
        ...value,
        ...area
      })
    }
  }, [area])

  useEffect(() => {
    if (!area && areaData?.data?.length) {
      setArea(areaData.data[0])
    }
  }, [areaData])

  return (
    <List.Item title={title} className={styles.phone_item + ' ' + className}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { value, onChange } = field
          onChangeRef.current = field
          // 实现 handleClear 函数
          const handleClear = () => {
            onChange({
              ...value,
              value: ''
            }) // 清空输入框的值
          }
          const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange({
              ...value,
              value: e.target.value
            })
            onChangeFocus?.(e.target.value)
          }

          return (
            <div className={styles.content}>
              {
                <div
                  onClick={() => setVisible(true)}
                  className={classNames(styles.area_code, {
                    [styles.activity]: !!value?.value?.length
                  })}
                >
                  <span>+{area?.areaCode?.replace('+', '') ?? 86}</span>
                  <div className={styles.v_line}></div>
                </div>
              }
              <input
                {...field}
                type="tel"
                value={value?.value}
                onChange={handleOnChange}
                placeholder={placeholder}
                autoComplete="off"
                maxLength={maxLength}
              />
              {!!value?.value?.length && (
                <div className={styles.clear} onClick={handleClear}>
                  <SpriteContainer
                    name="icon_login_del_day"
                    className={styles.icon}
                  />
                </div>
              )}
            </div>
          )
        }}
      />
      <DialogAreacode
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
        onConfirm={(area) => {
          setVisible(false)
          setArea(area)
        }}
      />
    </List.Item>
  )
}

export default PhoneItem
