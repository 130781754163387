import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { Outlet } from 'react-router'
import styles from './index.module.scss'
import BottomBar from './components/bottomBar'
import localCache from '@/utils/cache'

interface MainContextType {
  mainContentHeight: number
  /**
   * 展示热门比赛
   */
  showPopularCompetitions: boolean
  /**
   * 切换热门比赛
   */
  togglePopularCompetitions: ()=>void
}

const MainContext = createContext<MainContextType | null>(null)

const Main = () => {
  const [mainContentHeight, setMainContentHeight] = useState(
    window.innerHeight - 64
  )
  const bottomRef = useRef<HTMLDivElement>(null)
  const [showPopularCompetitions,setShowPopularCompetitions] = useState(JSON.parse(localCache.get('popular','true')))

  const togglePopularCompetitions = ()=>{
    const v = !showPopularCompetitions
    setShowPopularCompetitions(v)
    localCache.set('popular',JSON.stringify(v))
  }

  useEffect(() => {
    const handleResize = () => {
      if (!bottomRef.current) {
        setMainContentHeight(window.innerHeight)
      } else {
        const rect = bottomRef.current.getBoundingClientRect()
        setMainContentHeight(window.innerHeight - rect.height)
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <MainContext.Provider
      value={{
        mainContentHeight,
        showPopularCompetitions,
        togglePopularCompetitions
      }}
    >
      <div className={styles.main} >
        <div className={styles.content} style={{ minHeight: mainContentHeight }}>
          <Outlet />
        </div>
        <BottomBar ref={bottomRef} />
      </div>
    </MainContext.Provider>
  )
}

export default Main

export const useMain = () => {
  const context = useContext(MainContext)
  if (!context) {
    throw new Error('useMain must be used within an MainContext')
  }
  return context
}
