import { List } from 'antd-mobile'
import React, { ReactNode, useEffect } from 'react'
import styles from './index.module.scss'
import { Controller, useFormContext } from 'react-hook-form'
import SpriteContainer from '@/compoments/spriteContainer'
import SanitizedInput from '../sanitizedInput'

/**
 * GeneralItemProps 接口定义
 */
interface GeneralItemProps {
  /**
   * 输入框的名称，用于表单字段绑定
   */
  name: string

  /**
   * 列表项的标题，显示在输入框前
   */
  title?: string

  /**
   * 自定义样式类名，用于自定义样式
   */
  className?: string

  /**
   * 输入框的占位符文本，当输入框为空时显示
   */
  placeholder?: string

  /**
   * 最大文本数量
   */
  maxLength?: number

  /**
   * Readonly
   */
  readOnly?: boolean

  /**
   * type default 'text'
   */
  type?: string
  
  onChangeFocus?:(v:any)=> void
}

const GeneralItem = (props: GeneralItemProps) => {
  const {
    name,
    title,
    placeholder,
    className,
    maxLength = 15,
    readOnly,
    type = 'text',
    onChangeFocus
  } = props
  const { control } = useFormContext() // 使用useFormContext获取control对象

  return (
    <List.Item title={title} className={styles.general_item + ' ' + className}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { value, onChange } = field
          // 实现 handleClear 函数
          const handleClear = () => {
            onChange('') // 清空输入框的值
          }
          return (
            <div className={styles.content}>
              <SanitizedInput
                {...field}
                type={type}
                placeholder={placeholder}
                autoComplete="off"
                disabled={readOnly}
                // onChange={(e)=>{
                //   const inputValue = e.target.value;
                //   if (inputValue.length <= maxLength) {
                //     onChange(inputValue);
                //     onChangeFocus?.(e.target.value)
                //   }
                // }}
              />
              {!!value?.length && !readOnly && (
                <div className={styles.clear} onClick={handleClear}>
                  <SpriteContainer name='icon_login_del_day' className={styles.icon}/>
                </div>
              )}
            </div>
          )
        }}
      />
    </List.Item>
  )
}

export default GeneralItem
