import React, { useState, useEffect, useRef } from 'react'
import styles from './verificationCode.module.scss'
import classNames from 'classnames'
import { nextTick } from '@/commonHooks/nextTick'

interface VerificationCodeProps {
  /**
   * 输入框都被填充后触发的回调
   * @param v
   */
  onFinish?: (v: string) => void
  /**
   * 输入值改变时触发的回调
   */
  onChange?: (v: string) => void
  /**
   * 验证码输入框的值，受控模式
   */
  value?: string
  /**
   * 验证码的长度，根据长度渲染对应个数的输入框
   * 默认值4
   */
  length?: number
  /**
   * 模式 默认block
   */
  mode?: 'block' | 'line'
}

const VerificationCode: React.FC<VerificationCodeProps> = ({
  onFinish,
  onChange,
  value = '',
  length = 4,
  mode = 'block'
}) => {
  const [codes, setCodes] = useState<string[]>(Array(length).fill(''))
  const inputsRef = useRef<Array<HTMLInputElement | null>>([])

  useEffect(() => {
    // 如果 value 有变动且与内部状态不一致，更新内部状态
    if (value && value !== codes.join('')) {
      const newCodes = value.split('').slice(0, length)
      setCodes(newCodes.concat(Array(length - newCodes.length).fill('')))
    }
  }, [value, length])

  const handleChange = (index: number, char: string) => {
    // 更新 codes 状态
    const updatedCodes = [...codes]
    updatedCodes[index] = char
    setCodes(updatedCodes)

    // 调用 onChange 回调
    const codeStr = updatedCodes.join('')
    onChange?.(codeStr)

    // 当所有输入框都被填满时，调用 onFinish 回调
    if (!updatedCodes.includes('') && updatedCodes.length === length) {
      onFinish?.(codeStr)
    }
    // 自动聚焦到下一个输入框
    if (char && index < length - 1) {
      nextTick().then(() => {
        inputsRef.current[index + 1]?.focus()
      })
    }
  }

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    // 当按下退格键时，清空当前输入并聚焦到前一个输入框
    if (event.key === 'Backspace' && codes[index] === '' && index > 0) {
      inputsRef.current[index - 1]?.focus()
    }
  }

  const handleFocus = (index: number) => {
    // 查找第一个为空的输入框的索引
    const firstEmptyIndex = codes.findIndex((code) => code === '')

    // 如果聚焦的输入框不是第一个空的输入框，则跳转到第一个空的输入框
    if (firstEmptyIndex !== -1 && index > firstEmptyIndex) {
      inputsRef.current[firstEmptyIndex]?.focus()
    }
  }

  return (
    <div
      className={classNames(styles.verification_code, {
        [styles.line]: mode === 'line'
      })}
    >
      {Array.from({ length }).map((_, i) => (
        <input
          key={i}
          type="number"
          maxLength={1}
          className={styles.code_input}
          value={codes[i]}
          onChange={(e) => {
            const inputValue = e.target.value
            if (inputValue.length <= 1) {
              handleChange(i, inputValue)
            }
          }}
          placeholder={mode === 'line' ? '-' : ''}
          onFocus={() => handleFocus(i)}
          onKeyDown={(e) => handleKeyDown(i, e)}
          ref={(el) => (inputsRef.current[i] = el)}
        />
      ))}
    </div>
  )
}

export default VerificationCode
