import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react'
import styles from './index.module.scss'
import { List } from 'antd-mobile'
import GeneralItem from './compoments/general'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { isEqual } from 'lodash'
import PhoneItem from './compoments/phone'
import SmsCodeItem from './compoments/smsCode'
import { FormListProps } from './interface'
import CardTypeItem from './compoments/cardType'
import BanknameItem from './compoments/bankName'
import BankaddressItem from './compoments/bankAddress'
import UploadImage from './compoments/uploadImage'
import CryptoType from './compoments/cryptoType'
import CryptoProtocol from './compoments/cryptoProtocol'
import PasswordItem from './compoments/password'

const FormList = forwardRef((props: FormListProps, ref) => {
  const { columns, defaultValues, onChange } = props

  const methods = useForm({ defaultValues })
  const { watch, reset, getValues } = methods
  const formValues = watch()

  // 使用 useRef 保存前一次的 formValues
  const previousValuesRef = useRef<FieldValues>(formValues)

  useEffect(() => {

    if (!isEqual(previousValuesRef.current, formValues)) {
      previousValuesRef.current = formValues // 更新前一次的值
      if(onChange){
         onChange(previousValuesRef.current)
      }
    }
  }, [formValues])


  useImperativeHandle(ref, () => ({
    set: (fieldName: string, fieldValues) => {
      const currentValues = getValues()
      reset({ ...currentValues, [fieldName]: fieldValues })
    },
    formValues: formValues
  }))
  return (
    <FormProvider {...methods}>
      <form className={styles.form_list} autoComplete="off"  >
        <List>
          {columns.map((v, index) => (
            <div key={index}>
              {v.domType === 'general' && <GeneralItem {...v}  />}
              {v.domType === 'phone' && <PhoneItem {...v}     />}
              {v.domType === 'smsCode' && <SmsCodeItem {...v}  />}
              {v.domType === 'cardType' && <CardTypeItem {...v} />}
              {v.domType === 'bankName' && <BanknameItem {...v} />}
              {v.domType === 'bankAddress' && <BankaddressItem {...v} />}
              {v.domType === 'uploadImage' && <UploadImage {...v} />}
              {v.domType === 'cryptoType' && <CryptoType {...v}/>}
              {v.domType === 'cryptoProtocol' && <CryptoProtocol {...v}/>}
              {v.domType === 'password' && <PasswordItem {...v} />}
              {v.domType === 'customNode' && v.customNode}
              {/* 其他组件类型的渲染逻辑 */}
            </div>
          ))}
        </List>
      </form>
    </FormProvider>
  )
})

export default FormList
