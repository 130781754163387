import useStorage from '@/commonHooks/useStorage';
import { useEffect, useState } from 'react';

function getTimestamp() {
    return Math.floor(Date.parse(new Date().toString()) / 1000);
  }

const useSmsCountDown = (props) => {
  const [smsV, setSmsV, removeSmsV] = useStorage(props);
  const [secounds, setSecounds] = useState(0);
  useEffect(() => {
    if (smsV) {
      const delta = 60 - (getTimestamp() - Number(smsV));
      if (delta > 0) {
        setSecounds(delta);
      } else {
        setSecounds(0);
      }
    } else {
      setSecounds(0);
    }
  }, []);
  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (secounds > 0) {
        setSecounds(secounds - 1);
      } else {
        removeSmsV();
      }
    }, 1000);

    return () => {
      window.clearTimeout(timer);
    };
  }, [secounds]);

  const start = () => {
    setSmsV(getTimestamp());
    setSecounds(60);
  };
  return { secounds, start };
};

export default useSmsCountDown;
