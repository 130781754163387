import { apiFetcher } from '@/api/api'
import { aesEncrypt } from '@/api/apiCrypto'
import { usePreInfo } from '@/commonApi'
import { useMemo } from 'react'
import useSWRMutation from 'swr/mutation'

//验证图形验证码
export function useValidateGeeCheck() {
  const params = {
    path: '/site/api/v1/user/member/validateGeeCheck',
    type: 'post',
    needToken: false
  }
  return useSWRMutation(params, (params, arg: { arg: any }) => {
    return apiFetcher<any>(params, { ...arg })
  })
}

export function useApiLogin() {
  const params = {
    path: '/site/api/v1/user/login',
    type: 'post',
    needToken: false
  }
  return useSWRMutation(params, (params, arg: { arg: any }) => {
    return apiFetcher<any>(params, { ...arg })
  })
}

export function useApiRegister() {
  const { preInfo } = usePreInfo()

  const headerString: string = useMemo(()=>{
    const timestamp = Date.now()
    return JSON.stringify({
      tt: timestamp,
      xx: preInfo?.ip
    })
  },[preInfo])
  const params = {
    path: '/site/api/v1/user/register',
    type: 'post',
    needToken: false,
    customHeaders: {
      'x-api-hack-xxxxx': aesEncrypt(
        headerString,
        'string',
        process.env.REACT_APP_SECRET_KEY_HACK,
        process.env.REACT_APP_SECRET_IV_HACK
      )
    }
  }
  return useSWRMutation(params, (params, arg: { arg: any }) => {
    return apiFetcher<any>(params, { ...arg })
  })
}

export function useKaptchcate() {
  const params = {
    path: '/site/api/v1/user/member/kaptchcate',
    type: 'post',
    needToken: false
  }
  return useSWRMutation(params, (params, arg: { arg: any }) => {
    return apiFetcher<any>(params, { ...arg })
  })
}

// 发送急速登录短信验证码
export function useSendCodeTwo() {
  const params = {
    path: '/site/api/v1/user/sendCode/two',
    type: 'post',
    needToken: false
  }
  return useSWRMutation(params, (params, arg: { arg: any }) => {
    return apiFetcher<any>(params, { ...arg })
  })
}
