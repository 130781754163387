import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useHomeData } from '../../api'
import styles from './index.module.scss'
import { Element, Link, animateScroll as scroll, scroller } from 'react-scroll'
import classNames from 'classnames'
import { isEmpty, openUrl, openUrlSelf, pxToCurrentPx } from '@/utils'
import { wait } from '@/commonHooks/nextTick'
import { useGameJump } from './useGameJump'
import { useHome } from '../..'
import { useTranslation } from 'react-i18next'
import LoadingUI from '@/compoments/loadingUI'
import SpriteContainer from '@/compoments/spriteContainer'
import DialogConfirm from '@/compoments/dialogConfirm'
import { Toast } from 'antd-mobile'
import InputAmount from '@/compoments/inputAmount'
import { useGetBalanceData } from '@/commonApi'
import { useTransfer } from '@/views/transfer/api'
import Spin from '@/compoments/spin'

const imgsMap = {
  TY: {
    default: 'icon_sidebar_01_nor',
    active: 'icon_sidebar_01_sel',
    desc: 'SPORTS'
  },
  ZR: {
    default: 'icon_sidebar_02_nor',
    active: 'icon_sidebar_02_sel',
    desc: 'CASINO'
  },
  QP: {
    default: 'icon_sidebar_04_nor',
    active: 'icon_sidebar_04_sel',
    desc: 'BOARD'
  },
  DJ: {
    default: 'icon_sidebar_03_nor',
    active: 'icon_sidebar_03_sel',
    desc: 'ESPORTS'
  },
  CP: {
    default: 'icon_sidebar_05_nor',
    active: 'icon_sidebar_05_sel',
    desc: 'LOTTERY'
  },
  DZ: {
    default: 'icon_sidebar_06_nor',
    active: 'icon_sidebar_06_sel',
    desc: 'SLOT GAME'
  },
  BY: {
    default: 'icon_sidebar_07_nor',
    active: 'icon_sidebar_07_sel',
    desc: 'FISHING'
  }
}

const Games = () => {
  const { games, isLoading } = useHomeData()

  const { hideDownLoad } = useHome()

  const [activeTab, setActiveTab] = useState(games?.[0]?.category)
  const isClickRef = useRef<any>(null)
  const { data: balance } = useGetBalanceData()
  const [value, setValue] = useState<any>('')
  const [chooseData, setChooseData] = useState<any>()
  const calculatedHeight = useCallback(
    (index) => {
      const topHeight = pxToCurrentPx(145)
      const itemHeight = pxToCurrentPx(130)
      const gap = pxToCurrentPx(10)
      let itemLength = 0
      for (let i = 0; i < index; ++i) {
        itemLength += games?.[i]?.list?.length || 0
      }
      const totalHeight = topHeight + itemHeight * itemLength + itemLength * gap
      return totalHeight
    },
    [games]
  )

  const { jumpGame, visible, setVisible, error, trigger, isMutating } =
    useGameJump()
  const { trigger: triggerTransfer } = useTransfer()
  const { t } = useTranslation('home')

  const items = useMemo(() => {
    return [
      {
        category: 'TY',
        categoryName: '体育'
      },
      {
        category: 'ZR',
        categoryName: '真人'
      },
      {
        category: 'QP',
        categoryName: '棋牌'
      },
      {
        category: 'DJ',
        categoryName: '电竞'
      },
      {
        category: 'CP',
        categoryName: '彩票'
      },
      {
        category: 'DZ',
        categoryName: '电子'
      },
      {
        category: 'BY',
        categoryName: '捕鱼'
      }
    ]
  }, [])

  return (
    <div className={styles.games}>
      <div
        className={classNames(
          styles.left,
          hideDownLoad ? styles.hide_downLoad : styles.un_hide_downLoad
        )}
      >
        {items?.map((v, index) => {
          return (
            <Link
              key={v.category}
              to={v.category}
              spy
              offset={pxToCurrentPx(-165)}
              className={styles.link}
              onClickCapture={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (activeTab === v.category) {
                  return
                }
                isClickRef.current = true
                wait(520).then(() => {
                  isClickRef.current = false
                })
                setActiveTab(v.category)
                const top = calculatedHeight(index)
                window.scrollTo({
                  top: top,
                  behavior: 'smooth'
                })
              }}
              onSetActive={() => {
                if (!isClickRef.current) {
                  setActiveTab(v.category)
                }
              }}
            >
              <SpriteContainer
                className={classNames(styles.tab_item, {
                  [styles.active]: activeTab === v.category
                })}
                name={
                  activeTab === v.category
                    ? 'bg_sports_sidebar_bg_sel'
                    : 'bg_sports_sidebar_bg_nor'
                }
              >
                <div className={styles.tab_img}>
                  <SpriteContainer
                    name={
                      activeTab === v.category
                        ? imgsMap[v.category]?.active
                        : imgsMap[v.category]?.default
                    }
                  />
                </div>
                <div className={styles.tab_text}>{v.categoryName}</div>
              </SpriteContainer>
            </Link>
          )
        })}
      </div>
      <div className={styles.right}>
        <LoadingUI
          // loading={isLoading}
          loading={isEmpty(games) ? isLoading : false}
          fullSceen={false}
          data={games}
          height={window.innerHeight - pxToCurrentPx(400)}
        >
          {games?.map((v, i) => {
            return (
              <Element
                name={v.category}
                id={v.category}
                key={i}
                className={'section'}
              >
                <div className={styles.element_content}>
                  {v.list?.map((element, index) => {
                    return (
                      <div
                        className={classNames(styles.element, 'wm_img_cover')}
                        key={index}
                        onClick={() => {
                          setChooseData(element)
                          jumpGame(element)
                        }}
                        style={{
                          backgroundImage: `url(${require(`@/assets/images/home/img_venue_bg.js`)})`
                        }}
                      >
                        <div className={styles.element_img}>
                          <img src={element.appIconUrl01} />
                        </div>
                        <div className={styles.element_text}>
                          <div>{element?.zhName}</div>
                          <div>{element?.displayEnName}</div>
                          {!!element?.gamesCount && (
                            <div className={styles.game_count}>
                              <span className="din_alternate">
                                {element?.gamesCount}
                              </span>
                              <span>场</span>
                            </div>
                          )}
                        </div>

                        {element?.status === 2 && (
                          <div className={styles.maintain}>
                            <div className={styles.maintain_top}>
                              <SpriteContainer
                                name="icon_venue_under_maintenance"
                                scale={2.8}
                              />
                              <span>
                                {t('maintenance.venueUnderMaintenance')}
                              </span>
                            </div>

                            {!!element?.startAt && (
                              <span className={styles.maintain_time}>
                                {element?.startAt +
                                  ` ${t('maintenance.until')} ` +
                                  element?.endAt}
                              </span>
                            )}
                          </div>
                        )}

                        {isMutating && chooseData === element && (
                          <div className={styles.maintain}>
                            <Spin />
                          </div>
                        )}

                        {element?.venueTag === '1' && (
                          <SpriteContainer
                            name={'icon_official'}
                            className={styles.card_origin}
                          />
                        )}
                      </div>
                    )
                  })}
                </div>
              </Element>
            )
          })}

          <div
            className={classNames(
              styles.element,
              'wm_img_cover',
              styles.element_bottom
            )}
            style={{
              backgroundImage: `url(${require(`@/assets/images/home/waiting_placeholder_day`)})`
            }}
          ></div>
        </LoadingUI>
      </div>

      <DialogConfirm
        visible={visible}
        cancelText="直接进入"
        confirmText="确定"
        onClose={() => {
          setVisible(false)
        }}
        onCancle={() => {
          trigger({
            enName: chooseData?.channelCode,
            isActivity: true
          }).then((res) => {
            openUrlSelf(res?.data?.url)
          })
        }}
        onSubmit={async () => {
          if (!value) {
            Toast.show('请输入转入场馆的金额')
            return Promise.reject()
          }
          try {
            await triggerTransfer({
              ty: '1',
              amount: value,
              channelCode: chooseData?.channelCode,
              isActivity: true
            })
            const res = await trigger({
              enName: chooseData?.channelCode,
              isActivity: true
            })
            openUrlSelf(res?.data?.url)
            return Promise.resolve()
          } catch (error: any) {
            Toast.show(error?.message || JSON.stringify(error))
            return Promise.reject()
          }
        }}
        bodyClassName={styles.dialog_wrap}
        content={
          <div className={styles.dialog}>
            <div className={styles.d_title}>{'温馨提示'}</div>
            <div>{error?.message}</div>
            <div className={styles.d_item}>
              <span>中心钱包余额：</span>
              <span>{balance?.centerWalletBalance}</span>
            </div>
            <div className={styles.d_input}>
              <InputAmount
                placeholder="请输入转入场馆申请优惠的金额"
                value={value}
                onChange={(v) => {
                  setValue(v)
                }}
              />
            </div>
          </div>
        }
      />
    </div>
  )
}

export default Games
