import React, { useState } from 'react'
import styles from './index.module.scss'
import { useTheme } from '@/provides/themeProvider'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'
import { useHomeData } from '../../home/api'
import { useBannerJump } from '@/commonHooks/useBannerJump'
import { isEmpty, isValidURL } from '@/utils'
import { Toast } from 'antd-mobile'
import { useTranslation } from 'react-i18next'
import LoadingUI from '@/compoments/loadingUI'
import classNames from 'classnames'
import { useHome } from '../../home'

const LoadingImage = ({ src }: { src: string }) => {
  const { theme } = useTheme()
  return (
    <div className={styles.item_banner_image}>
      <img loading={'lazy'} src={src} />
    </div>
  )
}

const Banner = () => {
  const { banners, isLoading: loading } = useHomeData()
  const { jumpUrl, isLoading } = useBannerJump()
  const { t } = useTranslation('home')
  const { hideDownLoad } = useHome()
  return (
    <div className={classNames(styles.banner, {
      [styles.hide]: hideDownLoad,
      [styles.un_hide]: !hideDownLoad
    })}>
      <div className={styles.banner_continer}>
        <div className={styles.swiper}>
          <LoadingUI fullSceen={false} loading={isEmpty(banners) ? loading : false} data={[1]}>
            {
              <Swiper
                loop={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false
                }}
                modules={[Autoplay, Pagination]}
                pagination={{
                  clickable: true
                }}
                className={styles.swiper_content}
              >
                {banners?.map((v, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      onClick={() => {
                        if (isLoading) {
                          Toast.show(t('common.noRepeatClick'))
                          return
                        }

                        const { carouselType, activityId } = v
                        if (carouselType === 1 || carouselType === 2) {
                          const path = `/${v.activityH5Path ?? ''}`.replace(
                            '//',
                            '/'
                          )

                          console.log('path', path)
                          jumpUrl(path, activityId)
                          return
                        } else if (carouselType === 0) {
                          const url = v.carouselInfoUrl ?? ''
                          if (isValidURL(url)) {
                            window.open(url)
                          } else {


                            jumpUrl(url, activityId)
                          }
                        }

                        console.log(v?.carouselUrl)
                      }}
                    >
                      <LoadingImage src={v?.carouselUrl} />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            }
          </LoadingUI>
        </div>
      </div>
    </div>

  )
}

export default Banner
