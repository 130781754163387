import React, { ReactNode } from 'react'
import styles from './index.module.scss'
import Spin from '../spin'
import classNames from 'classnames'

interface SubmitButtonProps {
  onClick?: (e:any) => void
  text?: string | ReactNode
  loading?: boolean
  disabled?: boolean
  className?: string
  size?: 'small' | 'middle' | 'large'
}

const SubmitButton = (props: SubmitButtonProps) => {
  const { onClick, text, loading, disabled, className , size='large'} = props
  return (
    <div className={classNames(styles.submit_button,className, {
      [styles.small]: size === 'small',
      [styles.middle]: size === 'middle',
      [styles.large]: size === 'large'
    })}>
      <button onClick={onClick} disabled={disabled || loading}>
        {!loading ? text : <Spin />}
      </button>
    </div>
  )
}

export default SubmitButton
