import { useEffect, useLayoutEffect, useRef } from 'react'
import { useLocation, useNavigationType } from 'react-router'

// 创建一个全局的 blur 事件监听的 Hook
export const useGlobalInputBlur = () => {
  const scrollYRef = useRef(0)
  useEffect(() => {
    // 定义处理 blur 事件的函数
    const handleBlur = (event) => {
      // 确保事件源是 input 元素
      if (event.target.tagName === 'INPUT') {
        setTimeout(() => {
          window.scrollTo(0, scrollYRef.current)
        }, 0)
      }
    }
    const handleFocus = (event) => {
      // 确保事件源是 input 元素
      if (event.target.tagName === 'INPUT') {
        scrollYRef.current = window.scrollY
      }
    }

    // 添加全局的 blur 事件监听器
    document.addEventListener('blur', handleBlur, true) // 使用捕获阶段
    document.addEventListener('focus', handleFocus, true) // 使用捕获阶段

    // 清理函数，用于移除事件监听器
    return () => {
      document.removeEventListener('blur', handleBlur, true)
      document.removeEventListener('focus', handleFocus, true)
    }
  }, []) // 确保 callback 改变时更新侦听器
}

/**
 * 滚动到顶部
 */
export const useScrollToTop = () => {
  const navigationType = useNavigationType()
  const { pathname } = useLocation()
  useEffect(() => {
    // 当页面不是大刷新且是通过浏览器回退按钮返回时
    if (navigationType !== 'POP') {
      window.scrollTo(0, 0)
    }
  }, [navigationType, pathname])
}
