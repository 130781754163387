import React, { useEffect, useMemo, useRef, useState } from 'react'
import DialogSecurityCheck from '@/compoments/securityCheck'
import HeaderUI from '@/compoments/headerUI'
import { ListItemProps } from '@/compoments/formList/interface'
import FormList from '@/compoments/formList'
import { useUserInfo } from '@/commonApi'
import ErrorContent from '@/compoments/errorContent'
import styles from './index.module.scss'
import { isEmpty } from '@/utils'
import SubmitButton from '@/compoments/submitButton'
import { Popup, Toast } from 'antd-mobile'
import { RouterPath } from '@/router/routerPath'

interface PopupSecurityCheckProps {
  visible: boolean
  onClose?: (type?: 'success' | 'cancel' | 'colse') => void
  onSubmit?: (v: { phone: string; phoneCode: string }) => Promise<any>
}
const PopupSecurityCheck = (props: PopupSecurityCheckProps) => {
  const { visible, onClose, onSubmit } = props
  const [dialogVisible, setDialogVisible] = useState(false)
  const triggerCodeRef = useRef<any>(null)
  const [errorText, setErrorText] = useState('请输入手机验证码')
  const { data: userData } = useUserInfo()
  const { data: user } = userData || {}
  const formRef = useRef<any>(null)
  const formCodeRef = useRef<any>(null)
  const [buttonloading, setButtonloading] = useState(false)
  const columns: ListItemProps[] = useMemo(
    () => [
      {
        domType: 'general',
        name: 'phone',
        title: '手机号码',
        placeholder: '请输入您的手机号码',
        readOnly: true,
      },
      {
        domType: 'smsCode',
        name: 'smsCode',
        title: '手机验证码',
        placeholder: '请输入验证码',
        maxLength: 6,
        cate: '0',
        type: '4',
        onChangeFocus(v:string){
          if (!v) {
            setErrorText('请输入手机验证码')
            return
          } else {
            const _regex = /^\d{6,6}$/
            const isCodeValid = _regex.test(v)
            if (!isCodeValid) {
              setErrorText('请输入正确的手机验证码')
              return
            } else {
              if (!formCodeRef.current?.length) {
                setErrorText('请先发送正确手机号的验证码')
                return
              }
            }
          }
      
          setErrorText('')
       },
        intercept: (triggerCode) => {
          triggerCodeRef.current = triggerCode
          setDialogVisible(true)
          return true
        }
      }
    ],
    []
  )

  useEffect(()=>{
    if(visible) {
      formRef.current?.set?.('smsCode','')
    }
  },[visible])


  return (
    <Popup visible={visible} position="right" bodyStyle={{ width: '100%' }}>
      <HeaderUI
        title="安全校验"
        showBack
        onClickBack={() => onClose?.('colse')}
      />
      <div className={styles.body}>
        <ErrorContent errorText={errorText} className={styles.error} />
        <FormList
          ref={formRef}
          columns={columns}
          defaultValues={{
            phone: user?.phone
          }}
        />

        <SubmitButton
          text="确定"
          loading={buttonloading}
          disabled={!!errorText.length}
          className={styles.submit_button}
          onClick={async () => {
            try {
              setButtonloading(true)
              await onSubmit?.({
                phone: formCodeRef.current,
                phoneCode: formRef.current?.formValues?.smsCode
              })
              onClose?.('success')
            } catch (error: any) {
              const text = error?.message || JSON.stringify(error)
              Toast.show(text)
              setErrorText(text)
            } finally {
              setButtonloading(false)
            }
          }}
        />

        <div className={styles.botton_tip}>
          <span>如有需要，请</span>
          <span onClick={()=>{
            navigateTo(RouterPath.CustomerService)
          }}>联系客服</span>
        </div>
      </div>
      <DialogSecurityCheck
        visible={dialogVisible}
        onSubmit={async (v: any) => {
          try {
            formCodeRef.current = v
            await triggerCodeRef.current?.(v)
          } catch (error: any) {
            setErrorText(error?.message || JSON.stringify(error))
          }
        }}
        phone={user?.phone}
        onClose={() => {
          setDialogVisible(false)
        }}
      />
    </Popup>
  )
}

export default PopupSecurityCheck
