import React, { InputHTMLAttributes, useRef } from 'react'

// interface SanitizedInputProps extends InputHTMLAttributes<HTMLInputElement> {
//   /**
//    * 当前的值（受控）
//    */
//   value: string
//   /**
//    * 修改回调
//    */
//   onChange: (value: string) => void
//   /**
//    * 最大长度
//    */
//   maxLength?: number
//   /**
//    * 是否去除空格，默认 true
//    */
//   removeSpaces?: boolean
// }

const SanitizedInput: React.FC<any> = ({
  value,
  onChange,
  maxLength,
  removeSpaces = true,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const sanitizeValue = (raw: string) => {
    let result = removeSpaces ? raw.replace(/\s+/g, '') : raw
    if (maxLength) {
      result = result.slice(0, maxLength)
    }
    return result
  }

  return (
    <input
      {...rest}
      ref={inputRef}
      value={value}
      onInput={(e) => {
        const inputValue = (e.target as HTMLInputElement).value
        const sanitized = sanitizeValue(inputValue)
        if (sanitized !== value) {
          onChange(sanitized)
        }
      }}
      onPaste={(e) => {
        e.preventDefault()
        const pasted = (e.clipboardData || (window as any).clipboardData).getData('text')
        const sanitized = sanitizeValue(pasted)
        onChange(sanitized)
      }}
      onKeyDown={(e) => {
        // 阻止输入空格键
        if (e.code === 'Space') e.preventDefault()
      }}
    />
  )
}

export default SanitizedInput
