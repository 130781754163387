type StorageType = 'localStorage' | 'sessionStorage';

export class CacheApi {
  static storageType: StorageType = 'localStorage'; // 默认使用 localStorage

  // 内存缓存，避免频繁读写存储
  private static memoryCache: Map<string, any> = new Map();

  // 设置存储方式
  static setStorageType(type: StorageType) {
    this.storageType = type;
  }

  // 获取存储对象
  private static getStorage() {
    return this.storageType === 'localStorage' ? localStorage : sessionStorage;
  }

  // 序列化缓存对象
  private static serialize(data: any): string {
    return JSON.stringify(data);
  }

  // 反序列化缓存对象
  private static deserialize(data: string): any {
    return JSON.parse(data);
  }

  // 缓存数据
  static cacheData(params: { expired: number; cacheKey: string; data: any }) {
    const { expired = Infinity, cacheKey, data } = params;
    const cacheObject = {
      data,
      expired: expired === Infinity ? expired : Date.now() + expired * 1000,
    };
    
    // 更新内存缓存
    this.memoryCache.set(cacheKey, cacheObject);
    
    // 更新 localStorage 或 sessionStorage
    this.getStorage().setItem(cacheKey, this.serialize(cacheObject));
  }

  // 获取缓存数据
  public static getCacheData(params: { cacheKey: string; isUselastData?: boolean }) {
    const { cacheKey, isUselastData = true } = params;

    // 优先从内存缓存中获取
    if (this.memoryCache.has(cacheKey)) {
      const cacheObject = this.memoryCache.get(cacheKey);
      const isExpired = cacheObject.expired !== Infinity && Date.now() > cacheObject.expired;
      if (isExpired && !isUselastData) {
        this.clearCacheData(cacheKey); // 如果过期且不使用最后数据，删除缓存
        return null;
      }
      return cacheObject.data;
    }

    // 如果内存缓存没有，再从 localStorage 中获取
    const cacheItem = this.getStorage().getItem(cacheKey);
    if (!cacheItem) {
      return null;
    }

    const cacheObject = this.deserialize(cacheItem);
    const isExpired = cacheObject.expired !== Infinity && Date.now() > cacheObject.expired;

    if (isExpired) {
      if (!isUselastData) {
        this.clearCacheData(cacheKey); // 如果过期且不使用最后数据，删除缓存
        return null;
      }
    }

    // 更新内存缓存
    this.memoryCache.set(cacheKey, cacheObject);
    return cacheObject.data;
  }

  // 清除某一个缓存数据
  static clearCacheData(cacheKey: string) {
    this.memoryCache.delete(cacheKey); // 清理内存缓存
    this.getStorage().removeItem(cacheKey); // 删除存储中的数据
  }

  // 清除所有缓存数据
  static clearAllCacheData() {
    this.memoryCache.clear(); // 清理内存缓存
    this.getStorage().clear(); // 清理存储中的数据
  }
}
