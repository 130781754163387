import React from 'react'
import routes from './routes'

// 自动导出路由配置
// let AutomaticGeneratedRoutes: any = []
// if (process.env.NODE_ENV === 'development') {
//   // 引入页面配置文件的上下文
//   const pageContext = require.context('../views', true, /routerConfig\.ts$/)

//   // 辅助函数，用于从页面路径中生成路由路径
//   const generateRoutePath = (pagePath: string) => {
//     return pagePath.replace(/^\.\//, '').replace(/\/routerConfig\.ts$/, '')
//   }

//   const parsePath = (path) => {
//     const result = { path: '', params: null }
//     const pathParts = path.split('/')

//     // Iterate through the parts of the path
//     for (const part of pathParts) {
//       if (part.startsWith(':')) {
//         // Remove the leading colon and optional question mark
//         result.params = part.replace(':', '').replace('?', '')
//       } else {
//         // Append to the path if it's a regular path segment
//         if (result.path.length > 0) {
//           result.path += '/'
//         }
//         result.path += part
//       }
//     }

//     return result
//   }

//   // 辅助函数，用于构建路由对象
//   const buildRouteObject = (pagePath: string, pageConfig: any) => {
//     const routePath = generateRoutePath(pagePath)

//     if (pageConfig.default.lazyLoad === false) {
//       const route = {
//         path: `/${routePath.replace(/\//g, '/')}`,
//         ...pageConfig.default,
//         component: pageConfig.default.component
//       }
//       return route
//     } else {
//       const route = {
//         path: `/${routePath.replace(/\//g, '/')}`,
//         component: React.lazy(() => import(`../views/${routePath}`)),
//         ...pageConfig.default
//       }
//       return route
//     }
//   }

//   // 辅助函数，用于检查是否为子路由
//   const isChildRoute = (pagePath: string) => {
//     const splites = pagePath.split('/')
//     return splites.length > 2
//   }

//   // 循环每一个找到的配置文件路径
//   pageContext.keys().forEach((pagePath) => {
//     try {
//       const pageConfig = pageContext(pagePath)
//       if (isChildRoute(pagePath) && !pageConfig?.default?.isRootRouter) {
//         // 子路由
//         const routePathSplit = generateRoutePath(pagePath).split('/')
//         const parentRouteName = routePathSplit[0]
//         const childRoute = buildRouteObject(pagePath, pageConfig)

//         const result = parsePath(childRoute.path)
//         // 查找或创建父路由
//         let parentRoute = AutomaticGeneratedRoutes.find(
//           (route: { name: string }) => route.name === parentRouteName
//         )
//         if (!parentRoute) {
//           parentRoute = {
//             name: parentRouteName,
//             path: `/${parentRouteName}`,
//             component:
//               pageConfig?.default.lazyLoad === false
//                 ? pageConfig?.default?.component
//                 : React.lazy(() => import(`../views/${parentRouteName}`)),
//             children: [],
//             author: pageConfig?.default?.author
//           }
//           AutomaticGeneratedRoutes.push(parentRoute)
//         }
//         if (
//           childRoute?.path === parentRoute?.path &&
//           childRoute?.defaultRoute
//         ) {
//           parentRoute.defaultRoute = childRoute.defaultRoute
//         }

//         if (childRoute?.path === parentRoute?.path && childRoute?.customPath) {
//           parentRoute.customPath = childRoute.customPath
//         }

//         if (
//           childRoute?.path === parentRoute?.path &&
//           childRoute?.lazyLoad === false
//         ) {
//           parentRoute.component = childRoute.component
//         }

//         if ('/' + result.path !== parentRoute.path) {
//           // 添加子路由到父路由的 children 数组
//           parentRoute.children.push(childRoute)
//         } else {
//           parentRoute.path = childRoute.path
//         }
//       } else {
//         // 父路由
//         const route = buildRouteObject(pagePath, pageConfig)
//         route.name = route.path.substring(1) // 假设路由名称是路径的第一部分
//         AutomaticGeneratedRoutes.push(route)
//       }
//     } catch (error) {
//       console.error(`导入配置文件出错 ${pagePath}:`, error)
//     }
//   })
// } else {
//   AutomaticGeneratedRoutes = routes
// }

export default routes
