import { pxToCurrentPx } from '@/utils';
import React, { forwardRef, useEffect, useState } from 'react';

interface SpriteContainerProps {
    json?: string, 
    name: string, 
    className?: string, 
    style?: any, 
    children?: any,
    scale?: number // 倍数，例如2代表2倍图
    onClick?: (e?)=>void
    size?: number,  // 需要显示的目标尺寸
    designWidth? : number
}
const REACT_APP_RATIO = process.env.REACT_APP_RATIO

const SpriteContainer = ({ json = 'spriteIcon', name, className, style = {}, children, scale = Number(REACT_APP_RATIO ?? 2) ,onClick ,size,designWidth = 37.5}: SpriteContainerProps ,ref) => {
  const [spriteData, setSpriteData] = useState<any>(null);

  useEffect(() => {
    const loadJson = async () => {
      try {
        const response = require(`@/assets/${json}.json`);
        if (response.icons && response.icons[name] && response.spriteSize) {
          setSpriteData({
            position: response.icons[name],
            spriteSize: response.spriteSize
          });
        } else {
          console.warn(`Icon ${name} not found in ${json}.json`);
        }
      } catch (error) {
        console.error(`Error loading JSON file: ${json}.json`, error);
      }
    };

    loadJson();
  }, [json, name]);

  if (!spriteData) return null;

  const { position, spriteSize } = spriteData;

  return (
    <div
      ref={ref}
      className={className}
      onClick={(e)=>{onClick?.(e)}}
      style={{
        width: `${pxToCurrentPx(position.width,designWidth) / scale}px`, // 缩小宽度
        height: `${pxToCurrentPx(position.height,designWidth) / scale}px`, // 缩小高度
        backgroundImage: `url(${require(`@/assets/${json}.js`)})`,
        backgroundPosition: `-${pxToCurrentPx(position.x,designWidth) / scale}px -${pxToCurrentPx(position.y,designWidth) / scale}px`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: `${pxToCurrentPx(spriteSize.width,designWidth) / scale}px ${pxToCurrentPx(spriteSize.height,designWidth) / scale}px`, // 使用整个雪碧图的大小
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default forwardRef(SpriteContainer);