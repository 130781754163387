import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './index.module.scss'
import { useTheme } from '@/provides/themeProvider'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { RouterPath } from '@/router/routerPath'
import Marquee from '@/compoments/marquee'
import { useHomeData } from '../../home/api'
const img_bg_ash = require('@/assets/images/home/img_bg_ash.js')
import classNames from 'classnames'
import { useUserInfo } from '@/commonApi'
import { useAuth } from '@/provides/authProvider'
import { useHome } from '../../home'
import { copyBoard, openUrl } from '@/utils'
import { DotLoading, Popover, Toast } from 'antd-mobile'
import { useBannerJump } from '@/commonHooks/useBannerJump'
import DialogConfirm from '@/compoments/dialogConfirm'
import SpriteContainer from '@/compoments/spriteContainer'
import { useTotalBalance } from '@/views/withdraw/api'
import localCache from '@/utils/cache'

const Menu = () => {
  const { theme } = useTheme()
  const { t } = useTranslation('home')
  const { pathname } = useLocation()
  const { hideDownLoad } = useHome()
  const { data, mutate: mutateBalance } = useTotalBalance(false);

  const menus = [
    {
      path: RouterPath.Deposit,
      text: t('finance.deposit'),
      icon: theme === 'day' ? 'icon_deposit' : 'icon_deposit'
    },
    {
      path: RouterPath.Withdraw,
      text: t('finance.withdraw'),
      icon: theme === 'day' ? 'icon_withdrawal' : 'icon_withdrawal'
    },
    {
      path: RouterPath.Transfer,
      text: t('finance.transfer'),
      icon: theme === 'day' ? 'icon_transfer' : 'icon_transfer'
    },
    {
      path: RouterPath.Vip,
      text: 'VIP',
      icon: theme === 'day' ? 'icon_vip' : 'icon_vip'
    }
  ]
  const { notices } = useHomeData()
  const { userInfo } = useUserInfo()
  const { isLogin } = useAuth()

  const { jumpUrl, isLoading } = useBannerJump()
  const [showItem, setShowItem] = useState<any>(null)
  const showJump = useMemo(() => {
    if (!showItem) {
      return
    }
    const { jumpType, url, jumpCategory } = showItem
    {
      /* 0站内，1站外，2无连接 */
    }
    if (jumpCategory === 1) {
      return true
    }
    return jumpType === 0 || jumpType === 1
  }, [showItem])

  const [popup, setPopup] = useState(false)

  const containerRef = useRef<any>(null);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current?.contains?.(event.target)) {
      setPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  useEffect(() => {

    const token = window._syswm_token || localCache.get('authToken');
    if (token != undefined && token != null && token.length > 0) {
      mutateBalance();
    }

  }, [])

  return (
    <div
      className={classNames(styles.menu, {
        [styles.hide]: hideDownLoad,
        [styles.un_hide]: !hideDownLoad
      })}
    >
      <div
        className={classNames(styles.menu_content, 'wm_img_cover')}
        style={{
          backgroundImage: `url(${img_bg_ash})`
        }}
      >
        <Marquee
          notices={notices}
          className={styles.marquee}
          onClickItem={(index) => {
            const value = notices[index]
            setShowItem(value)
          }}
        />
        <div className={styles.line}></div>
        <div className={styles.bottom}>
          {isLogin && (
            <div className={styles.login}>
              <div>
                <img src={userInfo?.avatar} />
                <span>{userInfo?.name}</span>
                <SpriteContainer
                  name={`icon_vip_0${userInfo?.grade}`}
                  scale={2.4}
                />
              </div>
              <div>
                <span>￥</span>
                <span className="din_alternate">{data?.totalMoney}</span>

                <SpriteContainer
                  name={`icon_central_wallet`}
                  onClick={() => {
                    setPopup(!popup)
                    //点击其它区域关闭 setPopup(false)
                  }}
                  className={styles.wallet_icon}
                  ref={containerRef}
                >
                  {popup && <SpriteContainer
                    name={`img_center_wallet`}
                    className={styles.popver}
                  />}
                </SpriteContainer>
              </div>
              <div
                className={styles.ip}
                onClick={() => {
                  copyBoard('https//home88.com')
                }}
              >
                <span>永久地址:home88.com</span>
                <SpriteContainer name='icon_home_copy' />
              </div>
            </div>
          )}
          {!isLogin && (
            <div className={styles.un_login}>
              <div
                onClick={() => {
                  navigateTo('/login/sigin')
                }}
              >
                您还未登录
              </div>
              <div
                onClick={() => {
                  navigateTo('/login/sigin')
                }}
              >
                请先登录/注册后查看
              </div>
              <div
                className={styles.ip}
                onClick={() => {
                  copyBoard('https//home88.com')
                }}
              >
                <span>永久地址home88.com</span>
                <SpriteContainer name='icon_home_copy' />
              </div>
            </div>
          )}

          <div className={styles.bottom_right}>
            {menus.map((v, index) => {
              return (
                <div
                  className={styles.menu_item}
                  key={index}
                  onClick={() => {
                    navigateTo(v.path, {
                      state: {
                        formPath: pathname
                      }
                    })
                  }}
                >
                  <SpriteContainer name={v.icon} />
                  <span>{v.text}</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <DialogConfirm
        visible={!!showItem}
        onCancle={() => {
          setShowItem(null)
        }}
        className={styles.dialog}
        cancelText="关闭"
        confirmText="查看全部"
        onSubmit={async () => {
          const { category } = showItem
          if (category == 1 || category == 2) {
            navigateTo('/messageCenter/1/3')
          } else if (category == 3) {
            navigateTo(RouterPath.Deposit)
          }
          return Promise.resolve()
        }}
        content={
          <div className={styles.dialog_content}>
            <span>{showItem?.title}</span>
            <div>
              <span>{showItem?.content}</span>
            </div>
            {showJump && (
              <button
                onClick={() => {
                  if (showItem.jumpType === 0 || showItem.jumpCategory === 1) {
                    jumpUrl(showItem.url, showItem?.activityId)
                  } else if (showItem.jumpType === 1) {
                    openUrl(showItem.url)
                  } else {
                    Toast.show('未知类型: ' + showItem.jumpType)
                  }
                }}
              >
                {isLoading ? (
                  <DotLoading />
                ) : (
                  showItem.urlText || '点击查看活动详情～'
                )}
              </button>
            )}
          </div>
        }
      />
    </div>
  )
}

export default Menu
