import { apiFetcher, useSWRExpand } from '@/api/api'

export function useHotGames() {
  const fetcher = () => {
    return apiFetcher({
      path: '/game/api/v1/sport/queryHotGameDialog'
    })
  }
  const result = useSWRExpand('useHotGames', fetcher, {
    dedupingInterval: 60 * 1000,
    refreshInterval: 61 * 1000
  },undefined,true)
  const _hotGames = result?.data?.data || []
  let hotGames: any[] = []
  //如果hotGames 数量为3 的时候则补充当前
  if (_hotGames.length === 3) {
    hotGames = [..._hotGames, ..._hotGames]
  } else {
    hotGames = [..._hotGames]
  }
  return {
    isLoading: result.isLoading,
    hotGames,
    oHotGames: result?.data?.data || []
  }
}

export function useHomeData() {
  const fetcher = () => {
    const promise1 = apiFetcher({
      path: '/site/api/v1/advertising/queryBannerList'
    })
    const promise2 = apiFetcher({
      path: '/game/api/v1/venue/queryGameAppByType'
    })
    const promise3 = apiFetcher(
      {
        path: '/site/api/v1/advertising/queryNoticeList'
      },
      {
        arg: {
          category: '1,2'
        }
      }
    )
    return Promise.all([promise1, promise2, promise3])
  }

  const { hotGames, isLoading: hotLoading } = useHotGames()
  const result = useSWRExpand('useHomeData', fetcher, {
    dedupingInterval: 60 * 1000
  },undefined,true)

  const { data, isLoading } = result
  const banners = data?.[0]?.data || []
  const games = data?.[1]?.data || []
  const notices = data?.[2]?.data || []

  return {
    banners,
    games,
    notices,
    isLoading: hotLoading || isLoading,
    hotGames
  }
}
