export function nextTick() {
  return new Promise<any>((resolve, reject) => {
    const id = requestAnimationFrame(() => {
        resolve(true)
        cancelAnimationFrame(id)
    });
  });
}


export function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}