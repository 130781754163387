import React, { createContext, useContext, useEffect, ReactNode } from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';

// 定义 NavigateContext 的类型
const NavigateContext = createContext<NavigateFunction | null>(null);

interface NavigateProviderProps {
  children: ReactNode;
}

const appendQueryParams = function (path, queryParams) {
  var url = new URL(path, window.location.origin);
  var searchParams = url.searchParams;

  if(queryParams.icode || queryParams.rcode){
  // 使用 for...in 遍历 queryParams
  for (var key in queryParams) {
    if (queryParams.hasOwnProperty(key)) { // 确保是自身属性
      var value = queryParams[key];
      if (value !== undefined && value !== null) {
        searchParams.set(key, String(value)); // 将值转为字符串后添加到参数
      }
    }
  }
  }
  return url.pathname + '?' + searchParams.toString();
};


// 创建 NavigateProvider 组件，将 useNavigate 封装到 Context 中
export const NavigateProvider: React.FC<NavigateProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const win: any = window;

    // 默认需要拼接的参数（例如从全局或 LocalStorage 获取）
    const defaultParams = {
      icode: window.icode, // 示例参数
      rcode: window.rcode, // 示例参数
    };

    // 将 navigate 挂载到全局 window 对象
    win.navigateTo = (
      path: any,
      options: { replace?: boolean; state?: any } = {}
    ) => {
      if (typeof path === 'number') {
        // 如果 path 是数字，直接调用 navigate 实现后退或前进
        navigate(path);
      } else {
        // 确保 path 以 `/` 开头
        const normalizedPath = path?.[0] !== '/' ? '/' + path : path;

        // 拼接查询参数（保留现有参数）
        const finalPath = appendQueryParams(normalizedPath, defaultParams);

        // 调用 navigate
        navigate(finalPath, options);
      }
    };
  }, [navigate]);

  return (
    <NavigateContext.Provider value={navigate}>
      {children}
    </NavigateContext.Provider>
  );
};

// 可选：导出一个 Hook 方便其他地方使用（如果需要）
export const useNavigateTo = () => {
  const context = useContext(NavigateContext);
  if (!context) {
    throw new Error('useNavigateTo must be used within a NavigateProvider');
  }
  return context;
};
