import { useAuth } from '@/provides/authProvider'
import localCache from '@/utils/cache'
import { Toast } from 'antd-mobile'
import { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { EnmuSysOSPath, useNavigateToPlus } from './useNavigateToPlus'

export const useCommonErrorCode = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const jumpLoginRef = useRef(false)
  const { logout } = useAuth()
  const { navigateToPlus } = useNavigateToPlus()
  useEffect(() => {
    function receiveMessage(event) {
      if (
        event.data?.type === 'TokenInvalid' &&
        !pathname.includes('login') &&
        !jumpLoginRef.current
      ) {
        jumpLoginRef.current = true
        if(window._syswm_isNative) {
          navigateToPlus(EnmuSysOSPath.login)
          return
        }
        navigate('/login/sigin', {
          replace: true
        })
        localCache.remove('authToken')
        setTimeout(() => {
          jumpLoginRef.current = false
        }, 300)
        logout()
        Toast.show(event.data?.data?.message)
      }
    }
    window.addEventListener('message', receiveMessage, false)
    return ()=>{
      window.removeEventListener('message',receiveMessage)
    }
  }, [pathname])
}
