import { CascadePickerView, Popup } from 'antd-mobile'
import React, { useState } from 'react'
import styles from './index.module.scss'
import LoadingUI from '../loadingUI'
import options from './zh_address.json'

interface PopupBankAddressProps {
  visible?: boolean
  onClose?: () => void
  onConfirm?: (
    v: {
      label: string
      value: string
    }[]
  ) => void
}

const PopupBankAddress = (props: PopupBankAddressProps) => {
  const { visible, onClose, onConfirm } = props

  const [value, setValue] = useState<any>(['110000', '110100', '110111'])

  const handleConfirm = () => {
    // 遍历 value 数组，查找对应的 label 和 value
    const findLabels = (
      options: any[],
      values: string[]
    ): { label: string; value: string }[] => {
      let currentOptions = options
      const result: { label: string; value: string }[] = []
      for (const val of values) {
        const selectedOption = currentOptions.find(
          (option) => option.value === val
        )
        if (selectedOption) {
          result.push({
            label: selectedOption.label,
            value: selectedOption.value
          })
          currentOptions = selectedOption.children || []
        }
      }
      return result
    }

    const selectedItems = findLabels(options, value)
    onConfirm?.(selectedItems)
    onClose?.()
  }
  return (
    <Popup
      visible={visible}
      onMaskClick={() => onClose?.()}
      destroyOnClose={true}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <span>取消</span>
          <span onClick={handleConfirm}>确定</span>
        </div>
        <div className={styles.body}>
          <div className={styles.scroll}>
            <CascadePickerView
              options={options}
              value={value}
              onChange={(val) => {
                setValue(val)
              }}
            />
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default PopupBankAddress
