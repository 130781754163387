import { List } from 'antd-mobile'
import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import { Controller, useFormContext } from 'react-hook-form'
import PopupBankName from '@/compoments/popupBankName'
import SpriteContainer from '@/compoments/spriteContainer'

/**
 * BanknameItemProps 接口定义
 */
interface BanknameItemProps {
  /**
   * 输入框的名称，用于表单字段绑定
   */
  name: string

  /**
   * 列表项的标题，显示在输入框前
   */
  title?: string

  /**
   * 自定义样式类名，用于自定义样式
   */
  className?: string

  /**
   * 输入框的占位符文本，当输入框为空时显示
   */
  placeholder?: string

  onChangeFocus?: (v: any) => void
}

const BanknameItem = (props: BanknameItemProps) => {
  const { name, title, placeholder, className, onChangeFocus } = props
  const { control } = useFormContext() // 使用useFormContext获取control对象

  const [visible, setVisible] = useState(false)
  const [bank, setBank] = useState<any>()
  const fieldRef = useRef<any>(null)

  useEffect(() => {
    if (!fieldRef.current) {
      return
    }
    const { onChange } = fieldRef.current
    onChange({
      ...bank
    })
    onChangeFocus?.(bank?.bankName)
  }, [bank])

  return (
    <List.Item title={title} className={styles.bankname_item + ' ' + className}>
      <Controller
        name={name}
        control={control}
        render={({ field, }) => {
          fieldRef.current = field
          return (
            <div className={styles.content} onClick={() => setVisible(true)}>
              {!bank && (
                <span className={styles.placeholder}>{placeholder}</span>
              )}
              {bank && <span className={styles.text}>{bank?.bankName}</span>}

              <SpriteContainer
                className={styles.icon}
                name={'icon_return_left_day'} ></SpriteContainer>

            </div>
          )
        }}
      />
      <PopupBankName
        visible={visible}
        onClose={() => setVisible(false)}
        onConfirm={(v) => {
          setBank({ ...v })
        }}
      />
    </List.Item>
  )
}

export default BanknameItem
