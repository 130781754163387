import { Dialog } from 'antd-mobile'
import React from 'react'
import styles from './index.module.scss'
// import downloadImage from '@/assets/images/home/img_down_person.js'

import { useTheme } from '@/provides/themeProvider'
import { RouterPath } from '@/router/routerPath'
import { useTranslation } from 'react-i18next'
import SpriteContainer from '@/compoments/spriteContainer'
interface Props {
  visible: boolean
  onClose?: () => void
}

const DialogDownload = (props: Props) => {
  const { visible, onClose } = props

  const { theme } = useTheme()
  const { t: tPlatform } = useTranslation('platform')

  const { t } = useTranslation('downloadApp')

  return (
    <Dialog
      visible={visible}
      onClose={() => {
        onClose?.()
      }}
      closeOnMaskClick={true}
      className={styles.dialog}
      bodyClassName={styles.dialog_down}
      content={
        <div className={styles.dialog_content}>
          <SpriteContainer
            name="icon_x_circle"
            onClick={() => {
              onClose?.()
            }}
            className={styles.icon}
          />
          <img
            src={require('@/assets/images/home/img_down_person.js')}
            className={styles.img}
          />

          <div className={styles.content}>
            <div className={styles.content_div}>
              <SpriteContainer name={'h5_logo_download_day'} />
              <div>
                <span>{tPlatform('platformDownload01')}</span>
                <span>{t('appSlogan')}</span>
              </div>
              <button
                onClick={() => {
                  navigateTo(RouterPath.DownloadPage)
                }}
              >
                {t('downloadButton')}
              </button>
            </div>
          </div>
        </div>
      }
    />
  )
}

export default DialogDownload
