import React from 'react'
import { NoticeBar } from 'antd-mobile'
import classNames from 'classnames'
import './index.scss'
import SpriteContainer from '../spriteContainer'

interface MarqueeProps {
  className?: string
  notices: { content: string }[]
  onClickItem?: (index:any)=>void
}

const Marquee = (props: MarqueeProps) => {
  const { notices, className ,onClickItem} = props

  const prefix = 'wm-marquee'
  return (
    <div className={classNames(prefix, className)}>
      <div className={`${prefix}-notcie_bar`}>
        <NoticeBar
          delay={0}
          icon={
            <SpriteContainer
              name="icon_announcement"
              className={`${prefix}-icon`}
            />
          }
          content={
            <>
              {notices.map((v, index) => {
                return (
                  <span key={index} className={`${prefix}-item`}
                  onClick={()=>{
                    onClickItem?.(index)
                  }}
                  >
                    {v.content}
                  </span>
                )
              })}
            </>
          }
        />
      </div>
    </div>
  )
}

export default Marquee
