import { apiFetcher, useSWRExpand } from '@/api/api'
import useSWRMutation from 'swr/mutation'

//启动三方游戏接口
export function useLaunchThirdgames(params) {
  const fetcherFuc = () => {
    return apiFetcher(
      {
        path: '/game/api/v1/venue/launch',
        type: 'post'
      },
      {
        arg: params
      }
    )
  }
  const result = useSWRExpand(
    'useLaunchThirdgames' + JSON.stringify(params),
    fetcherFuc,
    {
      dedupingInterval: 10 * 1000
    },
    true
  )
  return result
}

export function useLaunchThirdgamesOut() {
  const params = {
    path: '/game/api/v1/venue/launch',
    type: 'post'
  }
  return useSWRMutation(params, (params, arg: { arg: any }) => {
    return apiFetcher<any>(params, { ...arg })
  })
}
