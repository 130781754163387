import React from 'react'

const InputAmount = (props: any) => {
  const { value, onChange , maxLength } = props
  return (
    <input
      {...props}
      type="number"
      value={value}
      onChange={(v)=>{
        if(v.target?.value?.length > maxLength){
            return
        }
        onChange(v.target.value)
      }}
      onKeyDown={(e: any) => {
        const allowedKeys = [
          'Backspace',
          'ArrowLeft',
          'ArrowRight',
          'Delete',
          'Tab'
        ]
        const value = e.target.value
        // 检查按键是否为数字、小数点或允许的功能键
        if (
          (!/^[0-9.]$/.test(e.key) || (e.key === '.' && value.includes('.'))) &&
          !allowedKeys.includes(e.key)
        ) {
          e.preventDefault()
        }
      }}
    />
  )
}

export default InputAmount
