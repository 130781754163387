import { useGeeSdk } from '@/sdk/gee/GeeSdk'
import { useValidateGeeCheck } from '@/views/login/api'
import { Toast } from 'antd-mobile'
import { useEffect } from 'react'

interface UseGeeProps {
  onSuccess: (data: any) => void;
  onError?: (error: any) => void;
  onClose?: ()=> void;
}

const useGee = ({ onSuccess, onError ,onClose}: UseGeeProps) => {
  const { trigger: validateGee, isMutating: geeMutating } =
    useValidateGeeCheck()

  const captcha = useGeeSdk({
    config: {
      captchaId: '770ff67331e82e6a3af65e203d70c84f',
      product: 'bind',
      protocol: 'https://'
    }
  })

  useEffect(() => {
    if (captcha.state === 'success') {
      validateGee({
        ...captcha?.getValidate()
      })
        .then((res) => {
          // 通知调用者验证成功并传递结果数据
          onSuccess(res)
          captcha.reset()
        })
        .catch((error) => {
          captcha.reset()
          // 如果定义了 onError 回调，通知调用者错误信息
          if (onError) {
            onError(error)
          } else {
            Toast.show(error?.message || JSON.stringify(error))
          }
        })
    } else if(captcha.state === 'close') {
        onClose?.()
        captcha.reset()
    }
  }, [captcha.state])

  //展示急速验证码
  const show = () => {
    captcha?.showCaptcha()
  }
  const reset = () => {
    captcha.reset()
  }

  return {
    show,
    reset,
    isMutating: geeMutating
  }
}

export default useGee
