import { Dialog, Toast } from 'antd-mobile'
import React, { ReactNode, useEffect, useState } from 'react'
import styles from './index.module.scss'
import VerificationCode from './verificationCode'

interface SecurityCheckProps {
  visible: boolean
  onClose?: (type?: 'success' | 'cancel' | 'colse') => void
  className?: string
  onSubmit?: (v?: string) => Promise<any>,
  phone?: string
}

const SecurityCheck = (props: SecurityCheckProps) => {
  const { visible, onClose ,phone} = props

  const [value, setValue] = useState('')

  useEffect(()=>{
    setValue('')
  },[visible])

  return (
    <>
      <Dialog
        visible={visible}
        destroyOnClose={true}
        onClose={() => {
          props?.onClose?.('colse')
        }}
        actions={[
          [
            {
              key: 'cancel',
              text: '取消',
              onClick: () => {
                onClose?.('cancel')
              }
            },
            {
              key: 'confirm',
              text: '确认',
              onClick: async () => {
                if(value?.length < 4) {
                  Toast.show('请输入手机号中间4位')
                  return
                }
                await props.onSubmit?.(phone?.replace(/\*{4}/, value))
                onClose?.('success')
              }
            }
          ]
        ]}
        bodyClassName={styles.security_check + ' ' + props?.className}
        content={
          <div className={styles.dialog_content}>
            <span>安全检测</span>
            <span>为了保障您的账户安全，请您输入手机号中间四位</span>
            <span>{phone}</span>
            <VerificationCode
              value={value}
              onChange={(v) => setValue(v)}
              mode={'block'}
            />
          </div>
        }
      />
    </>
  )
}

export default SecurityCheck
