import { useDesensitizeData, useUserInfo } from '@/commonApi'
import useGee from '@/commonHooks/useGee'
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import PopupSecurityCheck from '../popupSecurityCheck'
import { Toast } from 'antd-mobile'
import DialogConfirm from '../dialogConfirm'
import { nextTick } from '@/commonHooks/nextTick'
import { useLocalDesensitizeData } from './hooks'

/**
 * Fingerprint 类型表示每种脱敏的类型标识符
 */
export enum Fingerprint {
  /** 手机号指纹 */
  XsS0 = 'xsS0',
  /** 真实姓名指纹 */
  XsS1 = 'xsS1',
  /** 银行卡指纹 */
  XsS2 = 'xsS2',
  /** 邮箱指纹 */
  XsS5 = 'xsS5',
  /** 虚拟货币指纹 */
  XsS10 = 'xsS10',
  /** MPay/IPay/EBPay/Pay998/JD钱包/C币钱包/K豆钱包/钱能钱包/TG钱包/FPay钱包/OKPay钱包/TOPay钱包/GOPay钱包/808钱包账号指纹 */
  XsS35 = 'xsS35',
  /** 微信地址指纹 */
  XsS36 = 'xsS36',
  /** 支付宝账号指纹 */
  XsS37 = 'xsS37',
  /** 数字人民币地址指纹*/
  XsS38 = 'xsS38'
}

/**
 * 数据脱敏组件
 */
interface Props {
  onSuccess: (v: string) => void
}

// 定义 ref 的类型接口
export interface VerificationRef {
  show: (fingerprint: string, encryptedString: string) => void
}

const DesensitzeData = forwardRef<VerificationRef, Props>(
  (props: Props, ref) => {
    const { onSuccess } = props
    const { trigger, isMutating } = useDesensitizeData()
    const promiseController = useRef<any>(null)
    const [visible, setVisible] = useState(false)
    const [visibleSecurity, setVisibleSecurity] = useState(false)
    const { data, mutate } = useLocalDesensitizeData()
    const { userInfo } = useUserInfo()

    const [fingerprint, setFingerprint] = useState('')
    const [encryptedString, setEncryptedString] = useState('')
    /**
     * 有手机号，打开手机验证
     * 没有手机号，直接请求数据
     */
    const conditionalBranch = useCallback(() => {
      const hasPhone = !!userInfo?.phone?.length
      if (hasPhone) {
        setVisibleSecurity(true)
        nextTick().then(() => {
          promiseController.current.reslove(true)
        })
      } else {
        triggerDecryption().then(async () => {
          promiseController.current.reslove(true)
        })
      }
    }, [userInfo, data, fingerprint, encryptedString])

    /**
     * 极速验证通过
     */
    const handleGeeSuccess = useCallback(
      (res: any) => {
        // 处理成功后的逻辑，比如触发其他接口请求
        conditionalBranch()
      },
      [userInfo, encryptedString, fingerprint]
    )
    const triggerDecryption = useCallback(
      async (code?: any, fingerprint?: any, encryptedString?: any) => {
        try {
          const oData = await trigger({
            fingerprint,
            code,
            encryptedString: encryptedString
          })
          const finger = fingerprint + encryptedString
          mutate({
            ...data,
            [finger]: oData.data
          })
          onSuccess(oData.data)
          window.isVerifyEencryption = true
          return Promise.resolve(oData)
        } catch (error) {
          return Promise.reject(error)
        }
      },
      [fingerprint, data, encryptedString]
    )

    const handleGeeColse = useCallback(() => {
      promiseController.current?.reject()
    }, [])

    const { show } = useGee({
      onSuccess: handleGeeSuccess,
      onClose: handleGeeColse
    })

    /**
     * 开始验证
     */
    const startVerification = useCallback(
      (fingerprint, encryptedString) => {
        setFingerprint(fingerprint)
        setEncryptedString(encryptedString)
        const finger = fingerprint + encryptedString
        const localValue = data[finger]
        if (localValue?.length) {
          onSuccess(localValue)
          return
        }
        if (window.isVerifyEencryption) {
          (async () => {
            try {
              await triggerDecryption('', fingerprint, encryptedString)
            } catch (error: any) {
              Toast.show(error?.message || JSON.stringify(error))
              return Promise.reject(error)
            }
          })()
        } else {
          setVisible(true)
        }

      },
      [data]
    )

    useImperativeHandle(ref, () => ({
      /**
       * 开始验证验证
       */
      show: startVerification
    }))

    return (
      <>
        <DialogConfirm
          visible={visible}
          content={'为了您的隐私安全，我们需要对您进行安全校验'}
          onCancle={() => {
            setVisible(false)
          }}
          onClose={() => {
            setVisible(false)
          }}
          onSubmit={async () => {
            if (promiseController.current) {
              promiseController.current = null
            }
            const promise = new Promise((reslove, reject) => {
              promiseController.current = {
                reslove,
                reject
              }
            })
            show()
            return promise
          }}
        />
        <PopupSecurityCheck
          visible={visibleSecurity}
          onClose={() => {
            setVisibleSecurity(false)
          }}
          onSubmit={async ({ phone, phoneCode }) => {
            try {
              await triggerDecryption(phoneCode, fingerprint, encryptedString)
            } catch (error: any) {
              Toast.show(error?.message || JSON.stringify(error))
              return Promise.reject(error)
            }
          }}
        />
      </>
    )
  }
)

export default DesensitzeData
