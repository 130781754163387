import { Toast } from "antd-mobile"
import copy from "copy-to-clipboard"
export const openLink = (url) => {
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**处理金额，四舍五入后保留N位小数
 * @amount 金额
 * @fixed 小数位
 * @return 处理后的金额
 */
export const mathFloorFixed = (
  amount: number,
  fixed: number,
  accuracy = 100
) => {
  amount = Number(amount) ?? 0
  return (Math.floor(amount * accuracy) / accuracy).toFixed(fixed)
}

export function deepClone(obj: any) {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  const clonedObject: any = Array.isArray(obj) ? [] : {}

  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      clonedObject[key] = deepClone(obj[key])
    }
  }

  return clonedObject
}

export const formatNumberWithCommas = (value: any) => {
  // 先保留两位小数
  if (value == null || value == undefined) {
    value = 0
  }
  if (typeof value != 'number') {
    value = Number(value)
  }
  const roundedNumber = ((value * 100) / 100).toFixed(2)
  return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function generateRandomString(length) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function getFirstRoute(path) {
  // 使用 split 方法按 '/' 分割路径
  const segments = path.split('/')

  // 检查分割后的数组，获取第一个非空部分
  for (const segment of segments) {
    if (segment) {
      return segment
    }
  }

  // 如果路径为空或不包含有效部分，返回空字符串
  return ''
}

export function getSecondRoute(path) {
  // 使用 split 方法按 '/' 分割路径
  const segments = path.split('/')

  // 筛选出非空的部分，并检查是否有足够的部分
  const filteredSegments = segments.filter((segment) => segment)

  // 检查是否有第二部分
  if (filteredSegments.length >= 2) {
    return filteredSegments[1]
  }

  // 如果没有第二部分，返回空字符串
  return ''
}

/**pxToRem */
export function pxToRem(p: number,designWidth = 37.5) {
  return p / designWidth
}

export function pxToCurrentPx(p: number,designWidth = 37.5) {
  const rem = pxToRem(p,designWidth)
  const px = rem * parseFloat(window.document.documentElement.style.fontSize)
  return px
}

export function getQueryParam(url, param) {
  const queryString = url?.split?.('?')?.[1]
  if (!queryString) return null

  const params = queryString.split('&')
  for (let i = 0; i < params.length; i++) {
    const pair = params[i].split('=')
    if (decodeURIComponent(pair[0]) === param) {
      return decodeURIComponent(pair[1] || '')
    }
  }
  return null
}
export function isValidURL(url:any): boolean {
  const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  return regex.test(url);
}

export function isEmptyOrNullorUndefined(value: string | null | undefined): boolean {
  return value === "" || value === null  || value === undefined;
}

/**
 * Checks if the given data is not empty.
 *
 * @param {object} data - The data to check.
 * @returns {boolean} True if the data is not empty, false otherwise.
 */
export function isEmpty(data: object): boolean {
  return data === null || data === undefined || Object.keys(data)?.length == 0
}

export function copyBoard(text: any) {
  if(copy(text)) {
    Toast.show('复制成功')
  }else {
    Toast.show('复制失败')
  }
}


/**
 * 金额千分位分割处理
 */
export function formatAmount(amount: any) {
  return amount?.toLocaleString?.('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}


const validators = {
  name: (input) =>
    /^[a-zA-Z](?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{3,10}$/.test(input),
  password: (input) => /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,12}$/.test(input)
}

export function ValidateInput(input, type) {
  if (validators[type]) {
    return validators[type](input)
  } else {
    throw new Error(`No validator defined for type: ${type}`)
  }
}

/**
 * 数据是否已经脱敏 
 * @param value 
 * @returns 
 * true 已脱敏
 * false 未脱敏
 */
export function isDataMasked(value: string) {
  if(!value?.length) {
    return false
  }
  return !value.includes('*')
}


/**
 * 获取tab index
 * @param key 
 */
export function winState() {
  return window.history.state
}
/**
 * 替换tab index
 * @param key 
 */
export function winReplaceState(key: number,headerKey?:number) {
  window.history.replaceState({
    ...window.history.state,
    tabIndex: key,
    headerIndex:headerKey
  },'')
}

export function openUrl(url: string): any {
  if (window._syswm_os === 'IOS') {
    if (url) {
      window.open(url, '_blank');
    } else {
      return window.open('', '_blank');
    }
  } else {
    window.open(url, '_blank');
  }
}
export function openUrlSelf(url: string): any {
  if (window._syswm_os === 'IOS') {
    if (url) {
      window.location.href = url;
    } else {
      Toast.show('无效链接')
      return
    }
  } else {
    window.location.href = url;
  }
}

export function platformInvoke(inParams: {
  path: string;
  method?: string;
  params?: any;
  callback?: string;
  callbackParams?: any;
}) {
  if (!window._syswm_isNative) {
    return false;
  }
  const {path , method = '' , params = {} , callback= '',callbackParams} = inParams;
  if (window._syswm_os === 'ANDROID') {
    androidInvoke(path, method, params, callback, callbackParams);
    return true;
  } else if (window._syswm_os === 'IOS') {
    iosInvoke(path, method, params, callback, callbackParams);
    return true;
  }
  return false;
}

function androidInvoke(path: string, method: string, params = {}, callback: string, callbackParams?: any) {
  const methodParams = {
    path: path,
    method: method,
    params: params,
    webcallback: callback,
    callbackRefs: callbackParams, // 需要回传的函数
  };
  AndroidWebkit.callNativeMethod(JSON.stringify(methodParams));
}

function iosInvoke(path: string, method: string, params: any, callback: string, callbackParams: any) {
  const messageHandlers = (window as any).webkit.messageHandlers;
  if (messageHandlers != undefined) {
    if (messageHandlers.callNativeMethod != undefined) {
      const parmas = {
        path: path,
        method: method,
        params: params,
        webcallback: callback,
        callbackRefs: callbackParams, // 需要回传的函数
      };
      messageHandlers.callNativeMethod.postMessage(JSON.stringify(parmas));
      return;
    }
  }
}

/**
 * 判断跳转原生登录or原生指定路径
 * @param inParams 原生相关参数
 * @param h5Params h5项目相关参数 
 */
export function isPlatformInvoke(inParams: {
  path: string;
  method?: string;
  params?: any;
  callback?: string;
  callbackParams?: any;
},h5Params:{
  token:any
  routerPath?:string
  callbackFunc?: ()=>void;
}) {
  const {token,routerPath='',callbackFunc}=h5Params
  const { method = '' , params = {} , callback= '',callbackParams} = inParams;
  const path = token ?  inParams.path : '/user/login' 
  if(window._syswm_isNative && path){
    platformInvoke({path , method  , params , callback,callbackParams})
  }else{
    if(callbackFunc && token){
       callbackFunc()
    }else{
      const _routerPath = token ?  routerPath : 'login/sigin' 
      navigateTo(_routerPath);
    }
  }
}
