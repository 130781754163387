import React, { forwardRef, useEffect, useState } from 'react'
import styles from './index.module.scss'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@/provides/themeProvider'
import SpriteContainer from '@/compoments/spriteContainer'
const imgNavBarLight = require('@/assets/images/home/img-nav-bar-day.js')

const BottomBar = (props, ref) => {
  const { t } = useTranslation('home')
  const tabbarsMenu = ['/menu/myServices', '']

  const tabbars = [
    {
      path: `/deals/${0}`,
      text: t('navigation.promotions'),
      icon: 'icon_nav_offer_unsel',
      activeIcon: 'icon_nav_offer_sel'
    },
    {
      path: `/customerService`,
      text: t('navigation.customerService'),
      icon: 'icon_nav_kf_unsel',
      activeIcon: 'icon_nav_kf_sel'
    },
    {
      path: `/home`,
      type: 'middle',
      text: t('navigation.home'),
      icon: 'icon_nav_home',
      activeIcon: 'icon_nav_home'
    },
    {
      path: '/promotion',
      text: '推广',
      icon: 'icon_nav_promotion_unsel',
      activeIcon: 'icon_nav_promotion_sel'
    },
    {
      path: '/profile',
      text: t('navigation.myAccount'),
      icon: 'icon_nav_my_unsel',
      activeIcon: 'icon_nav_my_sel'
    }
  ]

  const navigate = useNavigate()
  const { pathname } = location

  const [activeKey, setActiveKey] = useState(pathname)
  const { theme } = useTheme()
  useEffect(() => {
    setActiveKey(pathname)
  }, [pathname])

  const isActive = (path: string) => {
    return (
      activeKey === path ||
      (path == tabbars[0].path && tabbarsMenu.includes(activeKey))
    )
  }

  return (
    <div className={styles.container + ' ' + 'wm_img_cover'} ref={ref}
    style={{
      backgroundImage: `url(${theme === 'night' ? imgNavBarLight : imgNavBarLight})`
    }}
    >
      <div className={styles.bottom_bar}>
        {tabbars.map((v, index) => {
          return (
            <div
              className={
                styles.tabbar_item +
                ' ' +
                (isActive(v.path) ? styles.active : '')
              }
              key={index}
              onClick={() => {
                navigateTo(v.path, { state: { type: v?.type } })
              }}
            >
              <>
         
              {
                v.type === 'middle' ? (
                  <div className={styles.tabbar_wrap}>
                  <div className={styles.in_1}>
                    <div className={styles.in_2}>
                    <SpriteContainer name={isActive(v.path) ? v.activeIcon : v.icon} className={styles.img}/>

                    </div>
                  </div>
                </div>
                ) : (
                  <SpriteContainer name={isActive(v.path) ? v.activeIcon : v.icon}/>
               
                )
              }
                 <span>{v.text}</span>
                 </>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default forwardRef(BottomBar)
