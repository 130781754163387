import React, { useState } from 'react'
import styles from './index.module.scss'
import { useTheme } from '@/provides/themeProvider'
import { useTranslation } from 'react-i18next'
import SpriteContainer from '@/compoments/spriteContainer'

interface HeaderProps {
  hideDownLoadDom: boolean
  onClickHideDownLoad: () => void
  openDownload: () => void
}

const DownLoad = (props: HeaderProps) => {
  const { onClickHideDownLoad, hideDownLoadDom, openDownload } = props
  // const [hideDownLoad, setHideDownLoad] = useState(hideDownLoadDom)
  const { theme } = useTheme()
  const { t: tPlatform } = useTranslation('platform')
  const { t } = useTranslation('home')
  return (
    <div className={styles.down_load}>
      {!hideDownLoadDom && (
        <div className={styles.header}>
          <div>
            <SpriteContainer
              className={styles.icon}
              name={'icon_return_del'}
              onClick={()=>{
                onClickHideDownLoad?.()
              }}
            />
          </div>
          <div>
            <SpriteContainer name="h5_logo_download_day" />
          </div>
          <div className={styles.desc}>
            <h1>{tPlatform('platformApp')}</h1>
            <span>{t('general.appDescription')}</span>
          </div>
          <div
            className={styles.download}
            onClick={() => {
              openDownload?.()
            }}
          >
            <button>{t('general.downloadNow')}</button>
          </div>
        </div>
      )}
    </div>
  )
}

export default DownLoad
