import { List } from 'antd-mobile'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './index.module.scss'
import { Controller, useFormContext } from 'react-hook-form'
// import { icon_deposit_item_selected } from '@/assets/base64/deposit'
import { useVcConfig } from '@/commonApi'
import SpriteContainer from '@/compoments/spriteContainer'
/**
 * CryptoProtocolProps 接口定义
 */
interface CryptoProtocolProps {
  /**
   * 输入框的名称，用于表单字段绑定
   */
  name: string
  /**
   * 列表项的标题，显示在输入框前
   */
  title?: string

  /**
   * 自定义样式类名，用于自定义样式
   */
  className?: string,
  onChangeFocus?:(v:any) => void
}

const CryptoProtocol = (props: CryptoProtocolProps) => {
  const { name, title, className,onChangeFocus } = props
  const { control } = useFormContext() // 使用useFormContext获取control对象

  const fieldRef = useRef<any>(null)
  const { data } = useVcConfig()
  const cards = useMemo(() => {
    return (
      data?.map((v) => {
        return {
          text: v?.name,
          protocol: v?.protocol
        }
      }) || []
    )
  }, [data])
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    if (!fieldRef.current) {
      return
    }
    const { onChange } = fieldRef.current
    onChange({
      ...cards[currentIndex]
    })
    onChangeFocus?.(cards[currentIndex])
  }, [currentIndex])

  return (
    <List.Item
      title={title}
      className={styles.card_type_item + ' ' + className}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          fieldRef.current = field
          // 实现 handleClear 函数
          return (
            <div className={styles.content}>
              {cards?.map?.((v, index) => {
                return (
                  <div
                    className={
                      styles.item +
                      ' ' +
                      (currentIndex === index ? styles.active : '')
                    }
                    key={index}
                    onClick={() => {
                      setCurrentIndex(index)
                    }}
                  >
                    <span>{v.text}</span>

                    {currentIndex === index && (
                      <SpriteContainer name='icon_deposit_selected_01' className={styles.checked}/>
                    )}
                  </div>
                )
              })}
            </div>
          )
        }}
      />
    </List.Item>
  )
}

export default CryptoProtocol
