import React from 'react'
import styles from './index.module.scss'
import SpriteContainer from '../spriteContainer'

interface ErrorContentProps {
  className?: string
  errorText?: string
}

const ErrorContent = (props: ErrorContentProps) => {
  const { className, errorText } = props
  return (
    <div className={styles.error_content + ' ' + className}>
      <div className={styles.content + ' ' + (!!errorText?.length ? styles.show : '')}>



        <SpriteContainer
          className={styles.icon}
          name={'icon_tips'}
        ></SpriteContainer>

        <span>{errorText}</span>
      </div>
    </div>
  )
}

export default ErrorContent
