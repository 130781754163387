import { apiFetcher, useSWRExpand } from '@/api/api'
import useSWRMutation from 'swr/mutation'
/**
 * 转账
 * @returns
 */
export function useTransfer() {
  const params = {
    path: '/game/api/v1/venue/transfer',
    type: 'post'
  }
  const result = useSWRMutation(params, (params, arg: { arg: any }) => {
    return apiFetcher<any>(params, { ...arg })
  })
  return result
}

/**
 * 是否开启自动转账
 */
export function useAutoTransfer() {
  const params = {
    path: '/game/api/v1/status/setAutoTransferStatus',
    type: 'post'
  }
  const result = useSWRMutation(params, (params, arg: { arg: any }) => {
    return apiFetcher<any>(params, { ...arg })
  })
  return result
}