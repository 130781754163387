// 自动生成的路径枚举文件
export enum RouterPath {
  AboutHobo = '/aboutHobo',
  ActivityDetails = 'activityDetails/:type',
  AddPay = '/addPay/:type?',
  AuthLogin = '/authLogin',
  AwardCheckinRecords = '/awardCheckinRecords',
  BetOrderRecords = '/betOrderRecords',
  Detail = '/deposit/detail',
  Deposit = '/deposit',
  DownloadGuide = '/downloadPage/downloadGuide',
  DownloadPage = '/downloadPage',
  SetupGuide = '/downloadPage/setupGuide',
  Feedback = '/feedback',
  FeedbackDetails = '/feedbackDetails',
  ForgetPassword = '/forgetPassword',
  HelpContact = '/help/helpContact',
  HelpDeposit = '/helpDeposit/:type',
  HelpDetail = '/help/helpDetail',
  HelpGame = '/help/helpGame',
  HelpIntroCoin = '/help/helpIntroCoin',
  HelpSupport = '/help/helpSupport',
  HelpTransfer = '/help/helpTransfer',
  HelpWithdaralUsdt = '/help/helpWithdaralUsdt',
  HelpWithdrawal = '/help/helpWithdrawal',
  Help = '/help',
  IdVerification = '/idVerification',
  JoinUs = '/joinUs',
  Login = '/login/:type?',
  LotteryRecord = '/lotteryRecord',
  Main = '/',
  Deals = '/deals/:type',
  Home = 'home',
  CustomerService = 'customerService',
  Profile = 'profile',
  Promotion = 'promotion',
  Management = '/management',
  MessageCenter = '/messageCenter/:messageType?/:type?',
  MyFeedback = '/myFeedback',
  MyWallet = '/myWallet',
  NormalRecords = '/normalRecords',
  OrderTransactionDetail = '/orderTransactionDetail',
  OrderTransactions = '/orderTransactions',
  BindEmail = '/personInfo/bindEmail',
  BindName = '/personInfo/bindName',
  BindPhone = '/personInfo/bindPhone',
  PersonInfo = '/personInfo',
  PrivacyAgreement = '/privacyAgreement/:type?',
  Settings = '/settings',
  ShareRecords = '/shareRecords',
  SlotMachine = '/slotMachine/:type?',
  ThirdpartH5 = '/thirdpartH5',
  ThirdpartyGames = '/thirdpartyGames',
  TopGiftExchangeRecords = '/topGiftExchangeRecords',
  Transfer = '/transfer',
  TxnDetails = '/txnDetails',
  UpdatePassword = '/updatePassword',
  Vip = '/vip',
  WalletExchange = '/walletExchange',
  WelfareRecords = '/welfareRecords',
  Withdraw = '/withdraw',
  WithdrawDetail = '/withdrawDetail',
}