import { AxiosTypes, ResponseTypes } from '@/net/axios/index.inter'
import { aesDecrypt, aesEncrypt } from './apiCrypto'

//加密body
export const getApiSign = (params: any) => {
  const pobj = params ? params : {}
  if (window.safeSwitch) {
    const requestBody = JSON.stringify(pobj)
    const encryptedBody = aesEncrypt(requestBody)
    return encryptedBody
  } else {
    return pobj
  }
}

export enum RequestCodeEnum {
  SUCCESS = 6000, // 成功
  TOKEN_INVALID = 6001, // TOKEN参数无效，会跳到登录窗口
}
console.log('process.env', process.env)
export const baseUrl =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_MAIN_API
    : `${window.location.origin}`
console.log('process.env', process.env.REACT_APP_MAIN_API)
export const config = {
  domainName: baseUrl,
  timeout: 10 * 1000,
  headers: {
    'X-API-SITE': process.env.REACT_APP_X_API_SITE,
    'Content-Type': 'application/json;charset=UTF-8',
    'X-API-CLIENT': window._syswm_platformName || 'h5',
    'x-api-xxx': process.env.REACT_APP_X_API_XXX,
    'jmpt': process.env.REACT_APP_X_JMPT,
    version: window._syswm_xApiVersion || process.env.REACT_APP_API_VERSION
  }
}

export const onDynamicHeader = () => {
  if (!window.visitorId || window.visitorId === 'undefined' || window.visitorId === undefined) {
    window.visitorId =
      'emptycode_' + Math.random().toString(36).substr(2)
    localStorage.setItem('gId', window.visitorId)
  }
  const headers = {
    'X-API-UUID': window.visitorId,
  }
  return headers
}

export const onHandleResponseData = <T>(
  response: AxiosTypes<ResponseTypes<T>>
) => {
  return new Promise<any>((resolve, reject) => {
    //解密body
    const decryptedResponse: any = response.data
    let data = decryptedResponse
    if (typeof decryptedResponse === 'string' && window.safeSwitch) {
      data = JSON.parse(aesDecrypt(decryptedResponse))
    }
    switch (data.status_code) {
      case RequestCodeEnum.SUCCESS:
        resolve(data)
        break
      case RequestCodeEnum.TOKEN_INVALID:
        window.postMessage({
          type: 'TokenInvalid',
          data: data
        },)
        reject(data)
        break
      default:
        reject(data)
        break
    }
  })
}
