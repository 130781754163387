import React from 'react';
import './index.scss';
import { pxToCurrentPx } from '@/utils';

const Spin = ({ className, count = 3 }: { className?: string; count?: number }) => {
  return (
    <div className={`wm-spin-loading ${className}`} style={{
      width: pxToCurrentPx(count  * 20,37.5)
    }}>
      {Array.from({ length: count }).map((_, index) => (
        <div key={index}></div>
      ))}
    </div>
  );
};

export default Spin;
