import { List } from 'antd-mobile'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './index.module.scss'
import { Controller, useFormContext } from 'react-hook-form'
import PopupBankAddress from '@/compoments/popupBankAddress'
import SpriteContainer from '@/compoments/spriteContainer'

/**
 * BanknameItemProps 接口定义
 */
interface BankAddressItemProps {
  /**
   * 输入框的名称，用于表单字段绑定
   */
  name: string

  /**
   * 列表项的标题，显示在输入框前
   */
  title?: string

  /**
   * 自定义样式类名，用于自定义样式
   */
  className?: string

  /**
   * 输入框的占位符文本，当输入框为空时显示
   */
  placeholder?: string
  onChangeFocus?: (v: any) => void
}

const BankaddressItem = (props: BankAddressItemProps) => {
  const { name, title, placeholder, className, onChangeFocus } = props
  const { control } = useFormContext() // 使用useFormContext获取control对象

  const [visible, setVisible] = useState(false)
  const [address, setAddress] = useState<any>()
  const fieldRef = useRef<any>(null)

  useEffect(() => {
    if (!fieldRef.current) {
      return
    }
    const { onChange } = fieldRef.current
    if (address?.length) {
      onChange([
        ...address
      ])
      onChangeFocus?.(address)
    } else {
      onChange([])
    }
  }, [address])

  const addressStr = useMemo(() => {
    if (!address) {
      return ''
    }
    return address?.map?.((v) => {
      return v.label
    })?.join?.(',')
  }, [address])

  return (
    <List.Item
      title={title}
      className={styles.bankaddress_item + ' ' + className}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          fieldRef.current = field
          return (
            <div className={styles.content} onClick={() => setVisible(true)}>
              {!address && (
                <span className={styles.placeholder}>{placeholder}</span>
              )}
              {address && (
                <span className={styles.text}>{addressStr}</span>
              )}


              <SpriteContainer name={'icon_return_left_day'} className={styles.icon} ></SpriteContainer>
            </div>
          )
        }}
      />
      <PopupBankAddress
        visible={visible}
        onClose={() => setVisible(false)}
        onConfirm={(v) => {
          setAddress(v)
        }}
      />
    </List.Item>
  )
}

export default BankaddressItem
