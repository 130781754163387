import { wait } from '@/commonHooks/nextTick'
import { useAuth } from '@/provides/authProvider'
import { openUrl, openUrlSelf } from '@/utils'
import { useLaunchThirdgames, useLaunchThirdgamesOut } from '@/views/thirdpartyGames/api'
import { Toast } from 'antd-mobile'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
/**
 */
export const useGameJump = () => {

  const { t } = useTranslation('home')

  const { trigger, error } = useLaunchThirdgamesOut()
  const [isMutating, setIsMutating] = useState(false)
  const [visible, setVisible] = useState(false)
  const { isLogin } = useAuth()

  useEffect(() => {
    if (error?.status_code === 6413) {
      setVisible(true)
    } else if (error) {
      Toast.show(error?.message || JSON.stringify(error))
    }
  }, [error])

  const jumpGame = (data: any) => {
    if (isMutating) {
      Toast.show('不要频繁操作，稍后再试')
      return
    }
    if (data?.type === 'placeholder') {
      Toast.show('敬请期待')
      return
    }
    if (!data) {
      return
    } else if (data.status === 2) {
      return Toast.show(t('maintenance.venueInMaintenance'))
    } else if (data.gameType === 'DZ') {
      //return Toast.show('功能还在开发中')
      navigateTo(`/slotMachine/${data?.enName}`, {
        state: {
          game: data,
          scrollY: window.scrollY
        }
      })
    } else {
      if (!isLogin) {
        navigateTo('/login/sigin')
        return
      }
      setIsMutating(true)
      trigger({
        enName: data?.channelCode,
        isActivity: false
      }).then((res) => {
        openUrlSelf(res?.data?.url)
        wait(3000).then(() => {
          setIsMutating(false)
        })
      })
    }
  }

  return {
    jumpGame,
    visible,
    setVisible,
    error,
    trigger,
    isMutating
  }
}