import { List } from 'antd-mobile'
import React, { ReactNode, useState } from 'react'
import styles from './index.module.scss'
import { Controller, useFormContext } from 'react-hook-form'
import SpriteContainer from '@/compoments/spriteContainer'

/**
 * PasswordItemProps 接口定义
 */
interface PasswordItemProps {
  /**
   * 输入框的名称，用于表单字段绑定
   */
  name: string

  /**
   * 列表项的标题，显示在输入框前
   */
  title?: string

  /**
   * 自定义样式类名，用于自定义样式
   */
  className?: string

  /**
   * 输入框的占位符文本，当输入框为空时显示
   */
  placeholder?: string

  /**
   * 最大文本数量
   */
  maxLength?: number

  /**
   * Readonly
   */
  readOnly?: boolean
}

const PasswordItem = (props: PasswordItemProps) => {
  const {
    name,
    title,
    placeholder,
    className,
    maxLength = 15,
    readOnly
  } = props
  const { control } = useFormContext() // 使用useFormContext获取control对象
  const [eyeState, setEyeState] = useState(false)
  return (
    <List.Item title={title} className={styles.general_item + ' ' + className}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { value, onChange } = field
          // 实现 handleClear 函数
          const handleClear = () => {
            onChange('') // 清空输入框的值
          }
          return (
            <div className={styles.content}>
              <input
                {...field}
                type={eyeState ? 'text' : 'password'}
                placeholder={placeholder}
                autoComplete="off"
                disabled={readOnly}
                onChange={(e) => {
                  const inputValue = e.target.value
                  if (inputValue.length <= maxLength) {
                    onChange(inputValue)
                  }
                }}
              />

              <div className={styles.clear}>
                {!!value?.length && !readOnly && (
                  <SpriteContainer
                    name="icon_login_del_day"
                    onClick={handleClear}
                    className={styles.icon}
                  />
                )}
                <SpriteContainer
                  className={styles.eye_icon}
                  onClick={() => {
                    setEyeState(!eyeState)
                  }}
                  name={
                    eyeState
                      ? 'icon_login_invisible_day'
                      : 'icon_login_visible_day'
                  }
                />
              </div>
            </div>
          )
        }}
      />
    </List.Item>
  )
}

export default PasswordItem
