import React, { ReactNode } from "react"
import styles from './index.module.scss'
import SubmitButton from "../submitButton"
/*
 * @Author: Mark
 * @Date: 2024-09-03 11:17:15
 * @LastEditTime: 2024-11-16 14:24:06
 * @LastEditors: MarkMark
 * @Description: 佛祖保佑无bug
 * @FilePath: /h5-skin-three/src/compoments/noData/index.tsx
 */
type NoDataType = 'def_common' | 'def_gowebsite' | 'def_nohm'
interface Props {
    noDataSrc?: NoDataType,
    btnText?: string | ReactNode,
    className?: string,
    cliclAction?: any,
    noDataButtonSize?: any
    descText: any
}


const NoData=(props)=>{
    const {btnText='',descText,className,cliclAction , noDataButtonSize = 'large' , noDataSrc='def_common'}=props
    return(
        <div className={styles.noData}>
             <img  className={styles.noData_img}  src={require(`@/assets/images/noData/${noDataSrc}.js`)} alt="" />
               { descText && <p  className={styles.desc} >{descText}</p>}
               { btnText && <SubmitButton className={className} text={btnText}  onClick={cliclAction} size={noDataButtonSize}></SubmitButton>}
        </div>
    ) 
}
export default NoData