import { apiFetcher, useSWRExpand } from '@/api/api'

import { useCallback, useEffect, useState } from 'react'
import { useAuth } from '@/provides/authProvider'
import useSWRMutation from 'swr/mutation'
import { Toast } from 'antd-mobile'
import { baseUrl } from '@/api/axiosConfig'
import axios from 'axios'
/**
 * 获取配置信息
 */
export function useUserInfo() {
  const { token } = useAuth()
  const fetcherFuc = () => {
    return apiFetcher(
      {
        path: '/site/api/v1/user/member/info',
        type: 'post'
      },
      {}
    )
  }
  const result = useSWRExpand('useUserInfo', token ? fetcherFuc : null, {
    dedupingInterval: 60 * 60 * 1000
  },undefined)
  const data = result?.data?.data
  return {
    ...result,
    userInfo: data
  }
}

/**
 * 获取果码
 */
export function useCountryArea() {
  const fetcherFuc = () => {
    return apiFetcher(
      {
        path: '/site/api/v1/site/countryArea/queryCountryArea',
        type: 'post',
        needToken: false
      },
      {}
    )
  }
  const result = useSWRExpand('useCountryArea', fetcherFuc, {
    dedupingInterval: 60 * 60 * 1000
  })
  return result
}

/**
 * 发送短信验证码
 */
export function useSendCode() {
  const params = {
    path: '/site/api/v1/user/sendCode/one',
    type: 'post'
  }
  const result = useSWRMutation(params, (params, arg: { arg: any }) => {
    return apiFetcher<any>(params, { ...arg })
  })
  useEffect(() => {
    if (result?.error) {
      Toast.show(result?.error?.message || JSON.stringify(result?.error))
    }
  }, [result.error])

  return result
}

/**
 * 获取银行卡列表
 */
export function useBankCardList() {
  const fetcherFuc = () => {
    return apiFetcher(
      {
        path: '/fd/api/v1/bankCard/withdraw/add/list',
        type: 'get'
      },
      {}
    )
  }
  const result = useSWRExpand('useBankCardList', fetcherFuc, {
    dedupingInterval: 60 * 60 * 1000
  })
  return result
}

/**
 * 代客充值暗号
 */
export function useSendAgentCode() {
  const params = {
    path: '/fd/api/v1/payment/createSignalCode',
    type: 'post'
  }
  const result = useSWRMutation(params, (params, arg: { arg: any }) => {
    return apiFetcher<any>(params, { ...arg })
  })
  return result
}

/**
 * 发起支付
 */
export function useSendAgentPay() {
  const params = {
    path: '/fd/api/v1/payment/getCustomerInfo',
    type: 'post'
  }
  const result = useSWRMutation(params, (params, arg: { arg: any }) => {
    return apiFetcher<any>(params, { ...arg })
  })
  return result
}

/**
 * 信息
 */
export function usePreInfo() {
  const fetcherFuc = () => {
    return apiFetcher(
      {
        path: '/site/api/v1/configuration/preInfo',
        type: 'post'
      },
      {}
    )
  }
  const result = useSWRExpand('usePreInfo', fetcherFuc, {
    dedupingInterval: 60 * 60 * 1000
  })
  const preInfo = result?.data?.data
  return {
    ...result,
    preInfo
  }
}

export const useGetBalanceData = (revalidateOnMount = true) => {
  const { token } = useAuth()
  const fetcher = () => {
    return apiFetcher(
      {
        path: '/fd/api/v1/transfer/getBalance'
      },
      {
        arg: {}
      }
    )
  }
  const result = useSWRExpand('useGetBalanceData', token ? fetcher : null, {
    dedupingInterval: 10 * 1000,
    revalidateOnMount: revalidateOnMount
  })
  const data = result?.data?.data
  return {
    ...result,
    data
  }
}

/**
 * 虚拟币协议配置
 */
export function useVcConfig() {
  const fetcherFuc = () => {
    return apiFetcher(
      {
        path: '/fd/api/v1/withdraw/usdtProtocol',
        type: 'post'
      },
      {}
    )
  }
  const result = useSWRExpand('useVcConfig', fetcherFuc, {
    dedupingInterval: 5 * 60 * 1000
  })
  const data = result?.data?.data
  return {
    ...result,
    data
  }
}

export function useUploadImage() {
  const { token } = useAuth()
  const [uploadState, setUploadState] = useState<{
    isMutating: boolean
    data: any
    errror: any
  }>({
    isMutating: false,
    data: undefined,
    errror: undefined
  })
  const trigger = useCallback(
    async (file) => {
      setUploadState({
        isMutating: true,
        data: undefined,
        errror: undefined
      })
      const url = `${baseUrl}/site/api/v1/user/upload/uploadImage`
      const formData = new FormData()
      formData.append('file', file)
      formData.append('category', '2')
      formData.append('sensitive', '1')
      try {
        const response = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-Api-Token': token || '',
            'X-API-SITE': process.env.REACT_APP_X_API_SITE || '',
            'X-API-CLIENT': 'h5',
            version: process.env.REACT_APP_API_VERSION || '',
            'x-api-xxx': process.env.REACT_APP_X_API_XXX || ''
          }
        })
        const fileUrl = response?.data?.data?.fileUrl
        setUploadState({
          isMutating: false,
          data: fileUrl,
          errror: undefined
        })
        return Promise.resolve(fileUrl)
      } catch (error) {
        setUploadState({
          isMutating: false,
          data: undefined,
          errror: error
        })
        return Promise.reject(error)
      }
    },
    [token]
  )

  return {
    ...uploadState,
    trigger
  }
}

/**
 * 信息脱敏
 */

export function useDesensitizeData() {
  const params = {
    path: '/site/api/v1/site/common/singleDataDecryption',
    type: 'post'
  }
  const result = useSWRMutation(params, (params, arg: { arg: any }) => {
    return apiFetcher<any>(params, { ...arg })
  })
  return result
}
