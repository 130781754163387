import { List } from 'antd-mobile'
import React from 'react'
import styles from './index.module.scss'
import { Controller, useFormContext } from 'react-hook-form'
import useSmsCountDown from '@/commonHooks/useSmsCountDown'
import { useSendCode } from '@/commonApi'

/**
 * SmsCodeItemProps 接口定义
 */
interface SmsCodeItemProps {
  /**
   * 输入框的名称，用于表单字段绑定
   */
  name: string

  /**
   * 列表项的标题，显示在输入框前
   */
  title?: string

  /**
   * 自定义样式类名，用于自定义样式
   */
  className?: string

  /**
   * 输入框的占位符文本，当输入框为空时显示
   */
  placeholder?: string
  /**
   * 最大文本数量
   */
  maxLength?: number
  /**
   * 发送按钮状态
   */
  disabled?: boolean
  /**
   * 
   * 是否正在加载中
   */
  isLoading?: boolean
  /**
   * 获取手机号
   */
  getPhone?: ()=> string
  /**
   * 
   * @returns 
   */
  cate?: string
  /**
   * 
   * @returns 
   */
  type?: string

  onChangeFocus?:(v:any)=> void

  /**
   * 发送验证码拦截
   */
  intercept?: (triggerCode:(phone:string)=>any, start: () => void) => boolean
}

const SmsCodeItem = (props: SmsCodeItemProps) => {
  const {
    name,
    title,
    placeholder,
    className,
    maxLength = 6,
    disabled,
    getPhone,
    cate='0',
    type='0',
    isLoading,
    onChangeFocus,
    intercept,
  } = props
  const { control } = useFormContext() // 使用useFormContext获取control对象
  const { secounds, start } = useSmsCountDown('smsCode')
  const { trigger, isMutating } = useSendCode()
  const sendCode = async () => {
    if (disabled || secounds || isMutating || isLoading) {
      return
    }
    let _intercept = false
    if (intercept) {
      _intercept = intercept(triggerCode,start)
    }
    if(_intercept) {
      return false
    }
    await triggerCode(getPhone?.())
  }

  const triggerCode = async (phone) => {
    try {
      await trigger({
        cate: cate,
        type: type,
        sendCodeNum: phone?.replace?.('+','')
      })
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    } finally {
      start()
    }
  }

  const buttonDisabled = disabled || secounds || isMutating || isLoading

  return (
    <List.Item title={title} className={styles.smscode_item + ' ' + className}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { value,onChange } = field
          return (
            <div className={styles.content}>
              <input
                {...field}
                type="tel"
                placeholder={placeholder}
                autoComplete="off"
                onChange={(e:React.FocusEvent<HTMLInputElement>) =>{
                  onChangeFocus?.(e.target.value)
                  onChange?.(e.target.value)
                }}
                maxLength={maxLength}
              />
              <div
                className={
                  styles.send_code +
                  ' ' +
                  (buttonDisabled ? styles.disabled : '')
                }
                onClick={()=>{
                  sendCode()
                }}
              >
                <span className={secounds ? styles.secounds : ''}>
                  {!!secounds ? `${secounds}s 后获取验证码` : '获取验证码'}
                </span>
                {isMutating || isLoading && <span className={styles.loader}></span>}
              </div>
            </div>
          )
        }}
      />
    </List.Item>
  )
}

export default SmsCodeItem
