import { apiFetcher, useSWRExpand } from '@/api/api'
import { useGetBalanceData, useUserInfo } from '@/commonApi'
import { nextTick, wait } from '@/commonHooks/nextTick'
import { Toast } from 'antd-mobile'
import { useCallback, useEffect, useRef, useState } from 'react'
import useSWRMutation from 'swr/mutation'
/**
 * 获取其它场馆余额
 */
export function useVenueBalanceList() {
  const fetcherFuc = () => {
    return apiFetcher(
      {
        path: '/game/api/v1/venue/allBalance',
        type: 'post'
      },
      {}
    )
  }
  const result = useSWRExpand('useVenueBalanceList', fetcherFuc, {
    dedupingInterval: 15 * 1000
  })
  const { data } = result
  const hasBalance = data?.data?.filter(
    (item: any) => item.money > 0 && item.status === 1
  )
  return {
    ...result,
    hasBalance,
    allBalance: data?.data
  }
}

// 获取总余额
export function useTotalBalance(revalidateOnMount = true) {
  const category = 8
  const fetcherFuc = () => {
    return apiFetcher(
      {
        path: '/game/api/v1/venue/allBalance',
        type: 'post'
      },
      {
        arg: {
          category
        }
      }
    )
  }
  const result = useSWRExpand(`useTotalBalance_${category}`, fetcherFuc, {
    dedupingInterval: 15 * 1000,
    revalidateOnMount
  })
  const { data } = result
  return {
    ...result,
    data: data?.data
  }
}

/**
 * 获取提款类型配置
 */
export function useWithdrawTypeCfg() {
  const fetcherFuc = () => {
    return apiFetcher(
      {
        path: '/fd/api/v1/withdraw/baseCfg/list',
        type: 'post'
      },
      {}
    )
  }
  const result = useSWRExpand('useWithdrawTypeCfg', fetcherFuc, {
    dedupingInterval: 60 * 1000
  })
  const { data } = result
  return {
    ...result,
    data: data?.data
  }
}

/**
 * 获取绑卡列表
 */
export function useCardList(type: any) {
  const fetcherFuc = () => {
    return apiFetcher(
      {
        ...type.api
      },
      { arg: {} }
    )
  }
  const result = useSWRExpand(
    'useCardList' + JSON.stringify(type),
    type !== undefined ? fetcherFuc : null,
    {
      dedupingInterval: 60 * 60 * 1000
    }
  )
  return result
}

/**
 * 获取提现配置信息
 */
export function useWithdrawCfg() {
  const fetcherFuc = () => {
    return apiFetcher(
      {
        path: '/fd/api/v1/withdraw/withdrawInfo',
        type: 'post'
      },
      {}
    )
  }
  const result = useSWRExpand('useWithdrawCfg', fetcherFuc, {
    dedupingInterval: 60 * 1000
  })
  const { data } = result
  return {
    ...result,
    data: data?.data
  }
}
/**
 * 取款
 */

export function useWithdraw() {
  const params = {
    path: '/fd/api/v1/withdraw/new',
    type: 'post'
  }
  const result = useSWRMutation(params, (params, arg: { arg: any }) => {
    return apiFetcher<any>(params, { ...arg })
  })
  return result
}

/**
 * 是否存在订单
 */
export function useIsExistOrder() {
  const params = {
    path: '/fd/api/v1/withdraw/withdrawOrderInfo',
    type: 'post'
  }
  const result = useSWRMutation(params, (params, arg: { arg: any }) => {
    return apiFetcher<any>(params, { ...arg })
  })
  const data = result?.data?.data
  return {
    ...result,
    data
  }
}

/**
 * 一键回收
 */
export function useRecovery() {
  const { mutate } = useGetBalanceData(false)
  const { mutate: mutateCfg } = useVenueBalanceList()
  const { userInfo } = useUserInfo()
  const fetcherFuc = async () => {
    try {
      if (userInfo.status === '1') {
        await apiFetcher(
          {
            path: '/game/api/v1/venue/recycle',
            type: 'post'
          },
          {}
        )
      }
      return Promise.all([mutate(), mutateCfg()])
    } catch (error) {
      Promise.reject(error)
    }
  }
  const result = useSWRExpand('useRecovery', fetcherFuc, {
    dedupingInterval: 60 * 1000
  })

  return {
    recovery: result.mutate,
    ...result
  }
}

export function useManualRecovery() {
  const { mutate } = useGetBalanceData(false)
  const { mutate: mutateCfg } = useVenueBalanceList()
  const fetcherFuc = async () => {
    try {
      await apiFetcher(
        {
          path: '/game/api/v1/venue/recycle',
          type: 'post'
        },
        {}
      )
      return Promise.all([mutate(), mutateCfg()])
    } catch (error) {
      Promise.reject(error)
    }
  }
  const result = useSWRExpand('manualRecovery', fetcherFuc, {
    dedupingInterval: 60 * 60 * 1000,
    revalidateOnMount: false
  })
  return {
    ...result
  }
}

/**
 * 获取虚拟币提款配置
 */
export function useWithdrawCfgInfo() {
  const fetcherFuc = () => {
    return apiFetcher(
      {
        path: '/fd/api/v1/withdraw/usdtWithdrawInfo',
        type: 'post'
      },
      {}
    )
  }
  const result = useSWRExpand('useWithdrawCfgInfo', fetcherFuc, {
    dedupingInterval: 60 * 1000
  })
  const data = result?.data?.data
  return {
    ...result,
    data
  }
}
