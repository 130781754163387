import {
  ImageUploadItem,
  ImageUploader,
  List,
  Toast
} from 'antd-mobile'
import React from 'react'
import styles from './index.module.scss'
import { Controller, useFormContext } from 'react-hook-form'
// import {
//   icon_upload_image,
//   icon_delete_icon
// } from '@/assets/base64/common'
import { useTheme } from '@/provides/themeProvider'
import { useUploadImage } from '@/commonApi'
import jsQR from 'jsqr'
import SpriteContainer from '@/compoments/spriteContainer'

/**
 * UploadImageProps 接口定义
 */
interface UploadImageProps {
  /**
   * 输入框的名称，用于表单字段绑定
   */
  name: string

  /**
   * 列表项的标题，显示在输入框前
   */
  title?: string

  /**
   * 自定义样式类名，用于自定义样式
   */
  className?: string

  /**
   * 最大上传图片数量
   */
  maxCount?: number
  /**
   * 数据验证
   */
  verify?: string
}

const UploadImage = (props: UploadImageProps) => {
  const { name, title, className, maxCount = 1, verify } = props
  const { control } = useFormContext() // 使用useFormContext获取control对象
  const { theme } = useTheme()
  const { trigger: upload } = useUploadImage()

  return (
    <List.Item title={title} className={`${styles.general_item} ${className}`}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { value = [], onChange } = field // value 默认是一个数组，保存图片URL

          // 文件上传处理函数
          const handleUpload = async (file: File): Promise<ImageUploadItem> => {
            // 检查文件大小是否超过15MB
            const fileSizeMB = file.size / (1024 * 1024)
            if (fileSizeMB > 15) {
              Toast.show('图片大小超过15MB，无法上传')
              throw new Error('文件大小超过15MB')
            }

            if (verify) {
              // 解码二维码
              const imageData = await fileToImageData(file) // 转换文件为 imageData
              const code = jsQR(
                imageData.data,
                imageData.width,
                imageData.height
              )

              if (!code || !code.data.includes(verify)) {
                Toast.show('二维码内容不正确')
                throw new Error('二维码验证失败')
              }
            }

            try {
              const fileUrl = await upload(file)
              // 更新图片列表并将新的URL传回表单
              if (!value || !value?.length) {
                onChange([fileUrl])
              } else {
                onChange([...value, fileUrl])
              }
              return { url: fileUrl }
            } catch (error) {
              console.error('文件上传失败', error)
              throw error
            }
          }

          // 处理删除图片
          const handleDelete = (item: ImageUploadItem) => {
            const updatedValue = value.filter((url: string) => url !== item.url)
            onChange(updatedValue) // 更新表单中的图片URL列表
          }

          return (
            <div className={styles.content}>
              <ImageUploader
                style={{ '--cell-size': `64px` }}
                multiple={maxCount > 1}
                maxCount={maxCount}
                showFailed={false}
                deleteIcon={
                  <div>
                    <img
                      src={'icon_delete_icon'}
                      className={styles.delete_icon}
                    />
                  </div>
                }
                showUpload={true}
                onDelete={handleDelete}
                upload={handleUpload}
              >
                <SpriteContainer name='icon_opinion_add'    className={styles.image}/>
              </ImageUploader>
            </div>
          )
        }}
      />
      <div className={styles.text}>
        <span>*文件格式为png、jpg、jpeg，且大小不超过15MB</span>
      </div>
    </List.Item>
  )
}

export default UploadImage

// 将 file 转换为 ImageData 的辅助函数
const fileToImageData = async (file: File): Promise<ImageData> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const img = new Image()
      img.src = reader.result as string
      img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        const context = canvas.getContext('2d')
        if (context) {
          context.drawImage(img, 0, 0)
          const imageData = context.getImageData(0, 0, img.width, img.height)
          resolve(imageData)
        } else {
          reject(new Error('无法获取 canvas context'))
        }
      }
      img.onerror = reject
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}
