import './styles/themes.scss'
import './styles/normalize.scss'
import './styles/anmations.scss'
import './styles/glob.scss'
import './styles/common.scss'
import './styles/ant_mobile.scss'
import '@/i18n/index'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import '@/sdk/flexible/flexible.js'
import { BrowserRouter } from 'react-router-dom'
import { SWRConfig } from 'swr'
import { AuthProvider } from './provides/authProvider'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { ThemeProvider } from './provides/themeProvider'
import { NavigateProvider } from './provides/navigateProvider'

window.safeSwitch = localStorage.getItem('safeSwitch') === '1'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <BrowserRouter>
    <NavigateProvider>
      <ThemeProvider>
        <AuthProvider>
          {/* <SocketProvider> */}
          <SWRConfig
            value={{
              //数据缓存有效期 默认不缓存
              dedupingInterval: 0,
              //错误后自动请求间隔
              errorRetryInterval: 3000,
              //错误后自动重试次数
              errorRetryCount: 0,
              //页面重新激活后是否重新请求
              revalidateOnFocus: false
            }}
          >
            <App />
          </SWRConfig>
          {/* </SocketProvider> */}
        </AuthProvider>
      </ThemeProvider>
    </NavigateProvider>
  </BrowserRouter>
)