import { Dialog } from 'antd-mobile'
import React, { ReactNode, useMemo } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'

interface DialogConfirmProps {
  visible: boolean
  onClose?: () => void
  onSubmit?: () => Promise<any>
  cancelText?: string
  confirmText?: string
  disabled?:boolean
  content?: string | ReactNode
  onCancle?: () => any
  bodyClassName?: string
  closeOnAction?: boolean
  hideCancel?: boolean
  className?: string
  hideConfirm?: boolean
}

const DialogConfirm = (props: DialogConfirmProps) => {
  const {
    closeOnAction = true,
    hideCancel = false,
    className,
    hideConfirm = false
  } = props

  return (
    <Dialog
      visible={props.visible}
      onClose={() => {
        props?.onClose?.()
      }}
      className={className}
      closeOnAction={closeOnAction}
      bodyClassName={classNames(styles.dialog_confirm, props?.bodyClassName, {
        [styles.hide_cancel]: hideCancel,
        [styles.hide_confirm]: hideConfirm
      })}
      actions={[
        [
          {
            key: 'cancel',
            text: props?.cancelText || '取消',
            onClick: async () => {
              await props.onCancle?.()
            }
          },
          {
            key: 'confirm',
            disabled:props?.disabled,
            text: props?.confirmText || '确认',
            onClick: async () => {
              await props.onSubmit?.()
            }
          }
        ]
      ]}
      content={
        <div className={classNames(styles.dialog_content)}>
          {props.content}
        </div>
      }
    />
  )
}

export default DialogConfirm
